<template>
  <v-app>
    <div class="bg_login"></div>
    <v-main>
      <v-container fill-height class="justify-center">
        <!-- DESKTOP VERSION -->
        <v-card v-if="!reset_password.opened" class="rounded-xl elevation-16 d-none d-sm-block" outlined width="400">
          <v-card-title class="text-center justify-center text-h2 font-weight-bold">
            Login
          </v-card-title>
          <v-card-subtitle class="mt-4 font-weight-medium text-center justify-center">
            {{$t('login.intro')}}
          </v-card-subtitle>
          <v-card-text class="mt-6">
            <validation-observer ref="login" v-slot="{ invalid, dirty }">
              <v-form @submit.prevent="submit">
                <v-row>
                  <v-col cols="12" class="text-caption font-weight-light">
                    <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                      <v-text-field 
                        v-model="username" 
                        label="Email\Username" 
                        dense outlined
                        :error-messages="errors">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-caption font-weight-light">
                    <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                      <v-text-field 
                        v-model="password" 
                        type="password" 
                        label="Password" 
                        dense outlined
                        :error-messages="errors">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" align="end" class="mx-0 px-0 mt-n6">
                    <v-btn
                      text
                      color="grey"
                      class="text-caption"
                      type="button"
                      @click="reset_password.opened = !reset_password.opened; login_dialog = !login_dialog;"
                    >
                      {{$t('login.forgot_password')}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" align="center">
                    <v-btn
                      depressed
                      color="primary"
                      class="text-white"
                      type="submit"
                      :disabled="invalid && !dirty"
                      rounded
                    >
                      {{$t('login.start')}}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="12" align="center">
                    <MicrosoftLogin />
                  </v-col>
                  <v-col cols="12" md="12" align="end">
                    <span>
                      {{$t('login.no_account')}}</span><v-btn text small @click="registerAccounts=false;registerAccounts = true">
                      {{$t('login.create_new')}}</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
          </v-card-text>
          <v-card-actions class="justify-center mb-4">
            <v-img @click="openWebsite" style="display: block; margin: auto; cursor:pointer;" src="@/assets/icons/logo_preto.jpg" max-width="150" />
          </v-card-actions>
        </v-card>
        <!-- DESKTOP VERSION END-->

        <!-- MOBILE VERSION -->
        <v-card v-if="!reset_password.opened" class="elevation-16 d-sm-none mobile-modal overflow-hidden" outlined>
          <v-card-actions class="justify-center mt-4">
            <v-img @click="openWebsite" style="display: block; margin: auto; cursor: pointer;" src="@/assets/icons/logo_preto.jpg" max-width="150" />
          </v-card-actions>
          <div class="align-center d-flex h-100 pb-16">
            <div>
              <v-card-title class="text-center justify-center text-h4">
                LOGIN
              </v-card-title>
              <v-card-subtitle class="mt-3 font-weight-medium text-center justify-center text-h6">
                Inicie sessão na sua conta IGNOTO
              </v-card-subtitle>
              <v-card-text class="mt-6">
                <validation-observer ref="login" v-slot="{ invalid, dirty }">
                  <v-form @submit.prevent="submit">
                    <v-row>
                      <v-col cols="12" class="text-caption font-weight-light">
                        <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="username" label="Email\Username" dense :error-messages="errors">
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-caption font-weight-light">
                        <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="password" type="password" label="Password" dense
                            :error-messages="errors">
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="py-0 mt-n2">
                        <v-btn text color="grey" class="text-caption mx-0 px-0"
                          style="text-transform: none; font-size: 14px !important;" type="button"
                          @click="reset_password.opened = !reset_password.opened">
                          Esqueceu-se da password?
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" class="py-0 grey--text">
                        <span class="h-100">Não tem conta?</span>
                        <v-btn text small @click="registerAccounts = false; registerAccounts = true"
                          class="ml-n2 text-underline" style="padding-bottom: 1px;">
                          Criar conta
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <MicrosoftLogin />
                      </v-col>
                    </v-row>
                    <v-btn depressed color="primary" class="text-white btn-bottom" type="submit"
                      :disabled="invalid && !dirty">
                      Iniciar sessão
                    </v-btn>
                  </v-form>
                </validation-observer>
              </v-card-text>
            </div>
          </div>
        </v-card>


        <!-- MOBILE VERSION END -->
      </v-container>
    </v-main>
    <!-- DESKTOP VERSION -->
    <v-dialog
      v-model="reset_password.opened"
      max-width="400"
      persistent
      transition="dialog-top-transition"
    >
      
        <v-card class="rounded-xl elevation-16 d-none d-sm-block" outlined width="400">
          <v-card-title class="text-center justify-center text-h6 font-weight-bold">
            <span class="orange-app text-uppercase">{{$t('login.recover')}}</span>
            <div style="position: absolute; right: 0;">
              <v-btn text fab @click="reset_password.opened = false; login_dialog = true;"><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <validation-observer v-if="!reset_password.reseted" ref="reset" v-slot="{ invalid }">
              <v-form @submit.prevent="reset">
                <v-container>
                  <v-row justify="center" class="mt-8">
                    <v-col cols="12" md="12">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                        <v-text-field
                          v-model="reset_password.email"
                          label="Email *"
                          dense outlined
                          type="email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" rounded depressed class="primary text-white" type="submit">
                        {{$t('login.recover')}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
            <v-container v-else>
              <v-row>
                <v-col
                    cols="12"
                    md="12"
                  >
                  <h2 class="text-start text-body-1">{{$t('login.email_sent')}}</h2>
                
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn rounded color="primary" @click="reset_password.opened = false; login_dialog = true">
                    {{$t('globals.close')}}
                  </v-btn>
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn text class="grey--text" @click="resend">{{$t('login.resend')}}</v-btn>
                </v-col>
                <v-col cols="12" align="center">
                  <v-alert                
                    v-if="email_sent"    
                    dense
                    outlined
                    type="success"
                  >
                    Email enviado
                  </v-alert>
                </v-col>
                
                <v-col cols="12" align="center">
                  <v-img class="mt-12"
                      @click="openWebsite"
                      style="cursor: pointer;"
                      src="@/assets/icons/logo_preto.jpg" 
                      max-width="175"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

        <v-card class="mobile-modal overflow-hidden d-sm-none">
          <v-card-actions class="justify-center mt-4">
            <v-img @click="openWebsite" style="display: block; margin: auto; cursor: pointer;" src="@/assets/icons/logo_preto.jpg" max-width="150" />
          </v-card-actions>
          <div style="position: absolute; right: 10px; top:12px;">
            <v-btn text fab @click="reset_password.opened = false;"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <div class="align-center d-flex h-100 pb-16">
            <div v-if="!reset_password.reseted">
              <v-card-title class="justify-center text-h4">
                <span class="orange-app text-uppercase word-break text-center">Recuperar password</span>
              </v-card-title>
              <v-card-text>
                <validation-observer ref="reset" v-slot="{ invalid }">
                  <v-form @submit.prevent="reset">
                    <v-container>
                      <v-row justify="center" class="mt-8">
                        <v-col cols="12" md="8">
                          <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                            <v-text-field v-model="reset_password.email" dense label="Email *" type="email" clearable
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-btn :disabled="invalid" rounded depressed class="primary text-white btn-bottom" type="submit">
                        Recuperar password
                      </v-btn>
                    </v-container>
                  </v-form>
                </validation-observer>
              </v-card-text>
            </div>
            <div v-else>
              <v-card-title class="justify-center text-h4">
                <span class="orange-app text-uppercase word-break text-center">Obrigado!</span>
              </v-card-title>
              <v-card-text>
                <validation-observer ref="reset">
                  <v-form @submit.prevent="reset">
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12" class="mt-6">
                          <h2 class="text-center text-body-1 black--text">Foi enviado um link para o seu email. Clique no
                            link para
                            redefinir
                            a sua nova password.</h2>

                        </v-col>
                        <v-col cols="12" align="center" class="mt-6">
                          <v-btn text class="grey--text" @click="resend">Voltar a enviar email</v-btn>
                        </v-col>
                      </v-row>
                      <v-btn rounded color="primary btn-bottom" @click="reset_password.opened = false">
                        Fechar
                      </v-btn>
                    </v-container>

                  </v-form>
                </validation-observer>
              </v-card-text>
            </div>
          </div>
        </v-card>
    </v-dialog>
    <!-- DESKTOP VERSION END-->
    <v-dialog v-if="registerAccounts" v-model="registerAccounts" fullscreen>
      <v-card class="elevation-0">
        <Register :flag="keyOpener" v-if="registerAccounts" @goToLogin="registerAccounts = false" />
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import User from "@/api/User.js"
import Register from "@/views/register_account/Register.vue"
import MicrosoftLogin from '@/components/ui/microsoft/Microsoft.vue'

export default {
  components: {
    Register,
    MicrosoftLogin
  },
  data: () => ({
    registerAccounts: false,
    login_dialog: true,
    username: "",
    password: "",
    email_sent: false,
    keyOpener: '',
    reset_password: {
      opened: false,
      email: "",
      reseted: false,
    }
  }),
  beforeCreate() {
    document.title = "Ignoto";
  },
  mounted() {
    this.$i18n.locale = this.$route.params.language == undefined ? 'pt' : this.$route.params.language
    //localStorage['auth'] = 0;
    if(this.$route.name == 'confirm-email'){
      this.keyOpener = 'confirm-email'
      this.registerAccounts = true
      return
    }
    
    if(this.$route.name == 'promocional'){
      localStorage['promocional'] = this.$route.params.code;
    }
    else if(this.$route.name == 'promocional-plan'){
      localStorage['promocional'] = this.$route.params.code;
      localStorage['plan'] = this.$route.params.plan;
    }

    if(this.$route.name == 'promocional-plan-register'){
      this.registerAccounts = true
      localStorage['promocional'] = this.$route.params.code;
      localStorage['plan'] = this.$route.params.plan;
    }
    
    if(this.$route.name == 'promocional-register'){
      this.registerAccounts = true
      localStorage['promocional'] = this.$route.params.code;
      localStorage['plan'] = this.$route.params.plan;
    }

    if(this.$route.name == "register-start-free"){
      this.registerAccounts = true
      localStorage['promocional'] = 'start-free';
      localStorage['plan'] = 'start-free';
    }

    

    if(this.$route.name == "email-promotion"){
      localStorage['promocional'] = this.$route.params.code;
      localStorage['plan'] = 'email-promotion';
    }
    

    if(this.registerAccounts == false){
      if (localStorage['auth'] == 1) {
        this.$router.push('/admin');
      }
    }else{
      localStorage['auth'] = 0
    }
    
    
            this.$gtag.event('call_tag222', {
              'event_category': 'AgendarChamada',
              'event_label': 'AgendarChamada',
              'value': 'call_tag222'
            })

            /*

            this.$gtag.event('mXa9CO2B67cZEPPE_uk9', {
                'event_category': 'teste',
                'event_label': 'teste',
                'value': 'mXa9CO2B67cZEPPE_uk9',
                'send_to': 'AW-16596836979/mXa9CO2B67cZEPPE_uk9',
            })

            */
  },
  methods:{
    openWebsite(){
      window.open('https://ignoto.eu/', '_blank')
    },
    submit () {
        this.$refs.login.validate().then((valid) => {
          if (!valid) {
            return;
          }

        User.logout()
          .then(() => {
            this.login();
          }).catch(() => {
            this.login();
          })
      });
    },
    login() {
      let pwd = this.$encryptValue(this.password)

      User.login(this.username, pwd)
        .then(({ data }) => {

          if (data.two_factor) {
            this.$router.push('/2fa');

            return;
          }

          localStorage['auth'] = 1;

          this.$router.push('/admin');
        }).catch(err => {

          if (err.response.status == 422) {

            this.$refs.login.setErrors(err.response.data.errors);

            return;
          }
        });
    },
    resend() {
      if (this.reset_password.email == null)
        return;
      User.reset(this.reset_password.email)
        .then(() => {

          this.reset_password.reseted = true;

          this.email_sent = true

          setTimeout(() => {
            this.email_sent = false
          }, "3000");
        }).catch(err => {

          if (err.response.status == 422) {

            this.$refs.reset.setErrors(err.response.data.errors);

            return;
          }

          this.reset_password.reseted = false;

        });
    },
    reset() {
      this.$refs.reset.validate().then((valid) => {
        if (!valid) {
          return;
        }

        User.reset(this.reset_password.email)
          .then(() => {

            this.reset_password.reseted = true;
          }).catch(err => {

            if (err.response.status == 422) {

              this.$refs.reset.setErrors(err.response.data.errors);

              return;
            }

            this.reset_password.reseted = false;

          });
      });
    },
  },
};
</script>

<style>
.text-white input {
  color: white !important;
}

.text-white label {
  color: white !important;
}

.text-white i {
  color: white !important;
}

.text-white v-messages__wrapper {
  color: white !important;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  /*background: url( '../assets/imagem_login.jpg') no-repeat center center;*/
  background-size: cover;
  background-color: red;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transform: scale(1.1);
}

.div_login {
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.div_login_mobile {
  opacity: 0.8;
}

.bg_login {
  width: 100%;
  height: 100%;
  position: absolute;
  /*background: url('../assets/backgrounds/circulo_banner.jpg') no-repeat center center;*/
  background-size: cover;
}

@media only screen and (max-width: 576px) {
  .theme--light.v-card>.v-card__subtitle {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>