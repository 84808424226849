<template>
    <v-container fluid>
        <v-row v-if="!loading">
            <v-col v-for="(item, index) in languages" :key="index" cols="12" md="2">
                <v-switch
                    :disabled="atLeastOne(item)"
                    v-model="item.selected"
                    inset
                    :label="item.extension"
                    @change="changedLanguage(item)"
                ></v-switch>
            </v-col>
        </v-row>
        <SuccessSnackBar v-model="success" :message="$t('account.success')" />
    </v-container>
</template>
<script>
import Languages from '@/api/Languages.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue'

export default{
    name: "AvailableLanguages",
    components:{
        SuccessSnackBar,
    },
    data(){
        return{            
            success: false,
            languages: [],
            loading: true,
            error: false
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{ 
        changedLanguage(input){
            Languages.updateLanguage(input).then(() =>  {
                this.success = true
            })
        },  
        async fillBaseData(){
            await Languages.getAvailableLanguages({}).then((resp) =>  {
                
                resp.data.forEach(element => {
                    this.languages.push({
                        language: element.language,
                        extension: element.extension,
                        selected: false
                    })
                });

            })

            await Languages.list().then((resp)  =>  {
                resp.data.forEach(tenant_language => {
                    this.languages.forEach(global_language => {
                        if(tenant_language.language == global_language.language)
                            global_language.selected = true
                    });
                });
            })
            
            this.loading = false
        },
        atLeastOne(item){
            let hasOne = 0
            this.languages.forEach(element => {
                if(element.selected)
                    hasOne++
            });

            if(hasOne == 1){
                if(item.selected)
                    return true;
                else return false
            }

            return false
        }
    }
}
</script>