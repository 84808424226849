import BaseApi from "./BaseApi";

export default class TenantCompany extends BaseApi{
    construct() {}


    static async find(tenant = null) {
        return await TenantCompany.getAxiosInstance().get(TenantCompany.getFullApiUrl(tenant));
    }

    static getFullApiUrl(tenant){
        let domain = tenant
        if(tenant == null)
            domain = location.pathname.split('/')[1]
            
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/tenant-company/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/tenant-company/";
    }
}