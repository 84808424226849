import BaseApi from "./BaseApi";

export default class Promocodes extends BaseApi{
    construct() {}
    
    static async check(params) {
        return await Promocodes.getAxiosInstance().get(Promocodes.getFullApiUrl() + 'check',{params: params});
    }

    static async getURL(url, place = null) {
        let data={
            place: place
        }

        return await Promocodes.getAxiosInstance().get(Promocodes.getFullApiUrl() + 'geturl/' + url, {params: data});
    }


    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/promocodes/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/promocodes/";
    }
}
