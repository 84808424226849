<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12">


            <h1 v-if="!error" v-show="!success" class="h1 pb-8 text-center">Confirmação de Email</h1>

            <v-container fluid v-if="!success">
             
            </v-container>
            <v-container fluid v-else>
              <v-row justify="center">
                <v-card class="elevation-0">
                  <v-card-title class="justify-center text-h2 font-weight-bold">
                    Obrigado!
                  </v-card-title>
                  <v-card-text class="mt-4 text-body-1">
                    A sua conta de login foi ativada com sucesso
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn rounded
                        color="primary btn-bottom"
                        class="mt-10"
                        @click="$router.push('/');"
                    >
                      Iniciar sessão
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-container>

            <div class="d-none d-sm-flex justify-center mt-14">
              <v-img 
                src="@/assets/icons/logo_preto.jpg" 
                max-width="175"/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import User from "@/api/User.js"

export default {
  components: {
  },
  data: () => ({
    error: false,
    errorText:"",
    success: false
  }),
  beforeCreate(){

    document.title = this.$t('app_name');

    if(localStorage['auth'] == 1){
      this.$router.push('/admin');
    }
  },
  mounted(){
    this.submit()
  },
  computed: {
    email: function(){
      return process.env.VUE_APP_EMAIL;
    }
  },
  methods:{
    submit () {
      let payload = {
        id: this.$route.params.id,
      }
      User.confirmEmail(payload)
        .then(() => {

            this.success = true;

            
        }).catch(err => {

          if(err.response.status == 422) {

            this.$refs.email.setErrors(err.response.data.errors);
            
            return;
          }

          this.error = true;

          this.errorText = err.message

      });
      },
  }
};
</script>
