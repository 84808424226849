import BaseApi from "./BaseApi";

export default class Complaints extends BaseApi{
    construct() {}

    static async getIntroduction() {
        return await Complaints.getAxiosInstance().get(Complaints.getFullApiUrl() + 'introduction');
    }

    static async getThankYou() {
        return await Complaints.getAxiosInstance().get(Complaints.getFullApiUrl() + 'thank-you');
    }

    static async search(params) {
        return await Complaints.getAxiosInstance().get(Complaints.getFullApiUrl(),{params: params});
    }

    static async update(params, id) {
        return await Complaints.getAxiosInstance().put(Complaints.getFullApiUrl() + id,params);
    }

    static async find(id) {
        return await Complaints.getAxiosInstance().get(Complaints.getFullApiUrl() + id);
    }

    static async getRelations(params) {
        let url = Complaints.getFullApiUrl() + "relations";

        return Complaints.getAxiosInstance().get(url,{params: params});
    }

    static async setIntroduction(params, id) {
        return await Complaints.getAxiosInstance().put(Complaints.getFullApiUrl() + 'introduction/' + id ,params);
    }

    static async setAttachment(params, id){
        return await Complaints.getAxiosInstance().post(Complaints.getFullApiUrl() + 'introduction-attachment/' + id ,params);
    }

    static async setThanks(params, id) {
        return await Complaints.getAxiosInstance().put(Complaints.getFullApiUrl() + 'thank-you/' + id ,params);
    }

    static async list(params) {
        let url = Complaints.getFullApiUrl() + "list";

        return Complaints.getAxiosInstance().get(url,{params: params});
    }

    static async downloadFile(file, id) {
        let url = Complaints.getFullApiUrl() + 'download/' + file + '/' + id
        
        return await Complaints.getAxiosInstanceDownload(url)
    }

    static async export(id, params) {
        let url = Complaints.getFullApiUrl() + "exports/downloads/" + id;

        return Complaints.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/complaints/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/complaints/";
    }
}