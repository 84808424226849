import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './plugins/validations'
import { ValidationObserver, ValidationProvider} from 'vee-validate';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@babel/polyfill'
import '@/assets/style.css'
import axios from "axios";
import router from './router'
import dayjs from 'dayjs'
import VCurrencyField from 'v-currency-field'
import DatetimePicker from 'vuetify-datetime-picker'
import VueLazyload from 'vue-lazyload'
import i18n from './i18n'
import { VueSpinners } from '@saeris/vue-spinners'
import EncryptValue from '@/plugins/encrypt'
import getCountryInitial from '@/plugins/getCountryInitial'
import VTiptap from "@peepi/vuetify-tiptap";
import Vuex from 'vuex'
import FlagIcon from 'vue-flag-icon'
import QrcodeVue from 'qrcode.vue'
import VueSession from 'vue-session'
import VueGtag from 'vue-gtag'
import msal from 'vue-msal'


Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP_MICROSOFT
  }
});



Vue.use( 
  {
    el: '#root',
    data: {
      value: 'https://example.com',
    },
    template: '<qrcode-vue :value="value"></qrcode-vue>',
    components: {
      QrcodeVue,
    },
  }
)

Vue.use(FlagIcon);
Vue.use(VueSession)

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);

Vue.use(Vuex)
const store = new Vuex.Store({
  configurations: null
})

Vue.use(VueLazyload)
Vue.use(VTiptap);
Vue.use(VueSpinners)

Vue.use(DatetimePicker);

axios.interceptors.request.use(
  function(config) {
        config.withCredentials = true;
        config.headers['Content-Type'] = "application/json";
        config.headers['Accept'] = "application/json";
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

Vue.prototype.$fatalError = true;

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    router.push('/')
  }
  return Promise.reject(error)
})

Vue.prototype.$encryptValue = EncryptValue

Vue.prototype.$getCountryInitial = getCountryInitial

Vue.mixin({
  methods: {
    setTheme: function(theme) {
      const dark = theme.dark;

        const light = theme.light;
        
        Object.keys(dark).forEach(i => {
            this.$vuetify.theme.themes.dark[i] = dark[i];
        });
        Object.keys(light).forEach(i => {
            this.$vuetify.theme.themes.light[i] = light[i];
        });
          
          this.$vuetify.groupSelectedColor = this.$vuetify.theme.dark ? theme.dark.primaryLight
          : theme.light.primaryLight;
    },
    formatDate(date){
      return date == null ? null : dayjs(date).format('DD/MM/YYYY');
    },
  }
});

Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})

Vue.use(VueGtag, {
  config: {
      id: 'AW-16596836979',
      params: {
          send_page_view: true
      }
  }
})


new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
