<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>{{$t('user.add_crumb')}}</span>
      </h1>

      <div class="mt-16">
        <user method="create"></user>
      </div>
  </section>
</template>
<script>
import User from '@/components/users/User'

export default {
  components: {
    User
  },
  data: () => ({
    
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "users.create"])) {
      this.$router.push('/admin');
    }

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
      {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: this.$t('menu.users_management'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin/settings/users',
          exact: true,
        },
        {
          text: this.$t('user.add_crumb'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
