<template>
    <section class="mt-16">
        <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
        ></v-breadcrumbs>

        <h1 class="primary--text">
            <v-icon color="primary">mdi-pencil-ruler</v-icon> <span>Tutorial</span>
        </h1>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="items.length"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :expanded.sync="expanded"
          :single-expand="true"
          show-expand
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >
        

        <template v-slot:expanded-item="{ item }">
           
           <!-- EXPORTAR REND MAO OBRA-->
           <td :colspan="headers.length + 1">
                <iframe :src="item.url" frameborder="0" 
                    class="mt-6"
                    webkitallowfullscreen="true" 
                    mozallowfullscreen="true" 
                    allowfullscreen style="width: 100%; height: 70vh;"></iframe>
            </td>
        </template>

        </v-data-table>
    </section>
</template>
<script>
export default{
    name:"Tutorial",
    data(){
        return{
            loading: false,
            options: {},
            expanded:[],
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{        
        fillBaseData(){
            this.loading = false;
        }
    },
    computed:{        
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        items(){
            let i = [
                {
                    id: '0',
                    how: this.$t('how_to_do.first_steps'),
                    description: this.$t('how_to_do.first_steps_msg'),
                    url: 'https://app.supademo.com/embed/cm16kwf7q0l0qme0p514p8x8u?embed_v=2',
                    permission: true
                },
            ]
            return i
        },
        headers(){
            let he = [                
                {
                    text: this.$t('how_to_do.title'),
                    sortable: true,
                    value: 'how',
                },
                {
                    text: this.$t('globals.description'),
                    sortable: true,
                    value: 'description',
                },
            ]
            return he
        },
        crumbs: function() {
            return [
                {
                text: this.$t('menu.dashboard'),
                disabled: false,
                to: '/admin',
                exact: true,
                },
                {
                text: 'Tutorial',
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
        }
    }
}
</script>