import BaseApi from "./BaseApi";

export default class State extends BaseApi{
    construct() {}

    static async search(params) {
        return await State.getAxiosInstance().get(State.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await State.getAxiosInstance().post(State.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await State.getAxiosInstance().put(State.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await State.getAxiosInstance().delete(State.getFullApiUrl() + id);
    }

    static async find(id) {
        return await State.getAxiosInstance().get(State.getFullApiUrl() + id);
    }

    static async list(params) {
        let url = State.getFullApiUrl() + "list";

        return State.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/categories/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/categories/";
    }
}