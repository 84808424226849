import BaseApi from "./BaseApi";

export default class Logger extends BaseApi{
    construct() {}
    
    static async search(params) {
        return await Logger.getAxiosInstance().get(Logger.getFullApiUrl(),{params: params});
    }

    static async getLoggerVars(){
        return await Logger.getAxiosInstance().get(Logger.getFullApiUrl() + 'getVarActions');
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/logs/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/logs/";
    }
}