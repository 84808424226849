import BaseApi from "./BaseApi";

export default class Configuration extends BaseApi{
    construct() {}

    static async list(params, tenant) {
        let url = Configuration.getFullApiUrl(tenant) + "list";

        return Configuration.getAxiosInstance().get(url,{params: params});
    }

    static async get(tenant = null) {
        return await Configuration.getAxiosInstance().get(Configuration.getFullApiUrl(tenant));
    }

    static getInstance(data) {
        let config = new Configuration(data);

        Object.assign(config, data);

        return config;
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]

        if(tenant != null)
            domain = tenant
            
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/configurations/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/configurations/";
    }
}