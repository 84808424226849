import { extend } from 'vee-validate';
import { required, email, numeric, min_value, max_value } from 'vee-validate/dist/rules'
import dayjs from 'dayjs'

extend('required', {
    ...required,
    message: '{_field_} é obrigatório',
})

extend('between_hour', {
  validate(value) {
    return value >= 0 && value <= 23
  },
  message: '{_field_} tem de estar entre 0 e 23',
})

extend('between_minute', {
  validate(value) {
    return value >= 0 && value <= 59
  },
  message: '{_field_} tem de estar entre 0 e 59',
})

extend('email', {
    ...email,
    message: '{_field_} deve ser um email válido',
})

extend('numeric', {
    ...numeric,
    message: '{_field_} deve ser numérico',
})

extend('min_value', {
    ...min_value,
    message: '{_field_} deve ser maior ou igual a {min}',
})

extend('max_value', {
    ...max_value,
    message: '{_field_} deve ser menout ou igual a {max}',
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Confirmação de password diferente'
});

extend("date_min", {
  params: ['target'],
    validate(value, { target }) {

        if (value.length == 0) {
            return true;
        }

        value = value.split("/");

        target = target.split("/");

        value = value[2] + "-" + value[1] + "-" + value[0];

        target = target[2] + "-" + target[1] + "-" + target[0];

        return dayjs(value).isSame(dayjs(target)) || dayjs(value).isAfter(dayjs(target));
  },
  message: "A data tem de ser maior ou igual a {target}!"
});

extend('password_size', {
  validate(value){
    if(value.length >= 9)
      return true
    return false
  },
  message: "Por favor insira pelo menos 9 carateres"
})

extend('special_char_1', {
  validate(value){
    return /[^A-Za-z0-9]/.test(value)
  },
  message: "Por favor insira 1 carater especial"
})

extend('capital_letter', {
  validate(value){
    return /[A-Z]{1}/.test(value)
  },
  message: "Por favor insira pelo menos 1 carater maiusculo"
})

extend('small_letter', {
  validate(value){
    return /[a-z]{1}/.test(value)
  },
  message: "Por favor insira pelo menos 1 carater minusculo"
})

extend('one_number', {
  validate(value){
    return /\d/.test(value)
  },
  message: "Por favor insira pelo menos 1 número"
})
extend('pt_nif', {
  validate(value){
    value = value + "";

    // has 9 digits?
    if (!/^[0-9]{9}$/.test(value)) return false;

    // is from a person?
    if (!/^[123]|45|5/.test(value)) return false;

    // digit check
    let tot =
      value[0] * 9 +
      value[1] * 8 +
      value[2] * 7 +
      value[3] * 6 +
      value[4] * 5 +
      value[5] * 4 +
      value[6] * 3 +
      value[7] * 2;
    let div = tot / 11;
    let mod = tot - parseInt(div) * 11;
    let tst = mod == 1 || mod == 0 ? 0 : 11 - mod;
    return value[8] == tst;
  },
  message: "O NIF não parece estar correto"
})