import BaseApi from "./BaseApi";

export default class Exports extends BaseApi{
    construct() {}

    static async export(params) {
        let url = Exports.getFullApiUrl() + "exports";

        return Exports.getAxiosInstance().get(url,{params: params});
    }

    static async delete(id) {
        return await Exports.getAxiosInstance().delete(Exports.getFullApiUrl() + 'file/' + id);
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/exports/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/exports/";
    }
}