import BaseApi from "./BaseApi";

export default class SubscriptionTypes extends BaseApi{
    construct() {}


    static async list(params) {
        let url = SubscriptionTypes.getApiUrl() + "list";

        return SubscriptionTypes.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/subscriptions-types/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/subscriptions-types/";
    }
}