<template>
  <section>
    <v-progress-linear v-if="loading"
      indeterminate
      class="global-loader"
    ></v-progress-linear>
    <v-container fluid v-if="!loading">
    <validation-observer ref="form" v-slot="{  }">
      <v-form @submit.prevent="submit"  class="mb-16">         
        <fieldset :disabled="loading">
          <v-tabs dark v-model="tab" fixed-tabs>
            <v-tab v-for="language in languages" :key="language.id">{{language.language}}</v-tab>
          </v-tabs>
          <v-row class="mt-4">
            <v-col cols="12" v-if="!canSave">
              <v-alert
                outlined
                type="error"
                border="left"
              >
                É necessário preencher a informação relativa a todos os idiomas. Em falta: {{getLanguageMissing()}}
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" :vid="$t('globals.reference')" name="Referência" rules="required">
                <v-text-field
                  v-model="fields.reference"
                  prepend-inner-icon="mdi-account-details"
                  :label="$t('globals.reference') + '*'"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="language in languages" :key="language.language">
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.name') + language.language" :name="$t('globals.name') + language.language" rules="required">
                    <v-text-field
                      v-model="fields.i18n[language.language]"
                      prepend-inner-icon="mdi-account-details"
                      :label="$t('globals.name') + '*'"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-btn :disabled="!canSave" depressed color="primary" type="submit">
              {{$t('globals.save')}}
            </v-btn>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
        {{$t('department.success')}}
      </DialogSuccess>
    </validation-observer>
  </v-container>
</section>
</template>
  <script>
  import Department from "@/api/Department.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import Languages from "@/api/Languages.js"
  
  export default {
    components: { 
      DialogSuccess
    },
    props: {
     department: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    department: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    data: () => ({
      tab: 0,
      languages: [],
      fields: {
        id: null,
        reference: null,
        i18n:{}
      },
      loading:true,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    mounted(){
      this.loading = true
      this.fillBaseData();
    },
    computed:{
      canSave(){
        let can = true

        this.languages.forEach(element => {
          if(this.fields.i18n[element.language] == null)
            can = false
        });

        return can
      }
    },
    methods:{
      getLanguageMissing(){
        let missing = ''
        this.languages.forEach(element => {
          if(this.fields.i18n[element.language] == null)
            missing = missing + element.language + ', '
        });

        return missing.slice(0, -2)
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/' + this.$session.get('domain') + '/admin/settings/departments');
      },
      async fillBaseData(){
        await Languages.list().then(({data}) => {
          this.languages = data;
        });       

        if(this.method == 'create'){
          Department.nextCode().then((response) =>  {
            this.fields.reference = response.data
          })
        }

        this.loading = false
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
          Department[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    }
  };
  </script>
  