<template>
    <v-dialog v-model="openDialog" fullscreen>
        <v-card class="elevation-0">
            <div class="center-screen">
                <v-container fluid v-if="true">
                    <v-row align="center" justify="center" class="py-16 my-16 ">
                        <v-col cols="12" align="center" class="text-h6">
                            <span>Olá {{$root.session.name}}</span>
                        </v-col>
                        <v-col cols="12" align="center" class="text-h4 py-4 my-4">
                            {{$t('subscriptions.ready')}}
                        </v-col>
                        <v-col cols="12" align="center" class="text-h3 py-4 my-4">
                            Vamos entrar no Portal.
                        </v-col>
                        <v-col cols="12" align="center" class="py-4 my-4">
                            <v-btn @click="closeWindow" x-large class="primary" rounded>Começar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import Dashboard from '@/api/Dashboard.js'

export default{
    name:"PaymentConfirmation",
    data(){
        return{
            openDialog: true
        }
    },    
    methods:{
        closeRegister(){
            Dashboard.markFirstView().then(() =>  {
                this.showWelcome=false
            })
        },
        closeWindow(){
            this.closeRegister()
            let domain = this.$session.get('domain')
            this.$router.push('/' + domain + '/admin')
        }
    }
}
</script>
<style>
.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
</style>