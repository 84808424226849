<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-file-outline</v-icon> <span>{{$t('menu.complaints_management')}}</span>
        </h1>
        <v-icon color="primary">
          mdi-eye
        </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
        {{$t('globals.visualize')}}
  
        <div style="position:relative">
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="info" v-if="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.search')}}</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
  
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16"
            locale="pt-pt"
          >

          <template v-slot:item.description="{item}">
            <span v-if="item.description != 'null'">{{item.description}}</span>
              <div v-else>
                  <v-btn fab x-small :class="isPlaying(item) ? 'error white--text' : 'purple white--text'" @click="togglePlay(item)">
                      <v-icon v-if="isPlaying(item)">mdi-stop</v-icon>
                      <v-icon v-else>mdi-play</v-icon>
                  </v-btn>
              </div>

              <!-- Elemento de áudio escondido, controlado pelos botões -->
              <audio :ref="'audioPlayer' + item.id" :src="getSrc(item)" @ended="audioEnded(item.id)"></audio>
          </template>

          <template v-slot:item.readed="{item}">
            <v-badge v-if="commentsToRead(item) > 0" overlap bordered color="red darken-4" :content="commentsToRead(item)">
              <v-icon :color="item.readed ? 'gray' : 'warning'">mdi-email-mark-as-unread</v-icon>
            </v-badge>
            <v-icon v-else :color="item.readed ? 'gray' : 'warning'">mdi-email-mark-as-unread</v-icon>
          </template>

          <template v-slot:item.state="{item}">
            {{item.state.i18n[$i18n.locale]}}
          </template>

          <template v-slot:item.deadline="{item}">
            <v-icon v-if="item.state.i18n['pt'] == 'Fechado'" color="primary">mdi-lock-check</v-icon>
            <v-chip v-else :color="chipColor(item)">{{item.deadline}}</v-chip>
          </template>

            <template v-slot:item.i18n="{item}">
              {{item.i18n[$i18n.locale]}}
            </template>
  
            <template v-slot:item.actions="{item}">
              <IconView
                class="mr-2"
                @click="viewComplaint(item)"
              />
            </template>
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetComplaint">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchComplaint">
              
              <v-list-item>
                <v-text-field
                  v-model="filter.code"
                  prepend-inner-icon="mdi-account-details"
                  label="Código"
                  clearable
                ></v-text-field>
              </v-list-item>
  
              <v-list-item>
                <v-text-field
                  v-model="filter.name"
                  prepend-inner-icon="mdi-account-details"
                  label="Nome"
                  clearable
                ></v-text-field>
              </v-list-item>
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
              {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>
    </section>
  </template>
  <script>
  import IconView from '@/components/ui/IconView.vue';
  import Complaints from "@/api/Complaints.js";
  import User from "@/api/ClientUsers.js";
  
  export default {
    components: {
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchComplaint()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = this.$t('app_name') + ' - ' + this.$t('menu.complaints_management');
    },
    data: () => ({
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        code: null,
        name:null,
      },
      users:[],
      playingAudio: null, // Para armazenar o áudio que está sendo reproduzido
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "complaints.read"])) {
        this.$router.push('/admin');
      }
  
      User.list({is_active: 1}).then(({data}) => {
        this.users = data;
      });
  
      this.fillBaseData();

    },
    methods:{
      getSrc(item){
          return process.env.VUE_APP_API_URL + this.$route.params.tenant + '/3Jk9lRf2Qb8wTg5Yh1pEwA3uZ/N7xXb2QpA4k8Yw1Ff2jLqV5mZ/T8n6Hc4RjQ1eWq9Fk5ZpJ8yXh/' + item.id
      },
      togglePlay(item) {
        const audio = this.$refs['audioPlayer' + item.id]; // Obter o elemento de áudio correto com base no item.id

        if (this.playingAudio === item.id) {
            audio.pause();
            this.playingAudio = null; // Parar o áudio e resetar o estado
        } else {
            if (this.playingAudio) {
                // Pausar o áudio anterior
                const previousAudio = this.$refs['audioPlayer' + this.playingAudio];
                previousAudio.pause();
                previousAudio.currentTime = 0; // Reiniciar o áudio anterior
            }
            audio.play();
            this.playingAudio = item.id; // Atualizar o áudio que está tocando
        }
      },
      audioEnded(itemId) {
        if (this.playingAudio === itemId) {
            this.playingAudio = null; // Reseta o estado quando o áudio termina
        }
      },
      isPlaying(item) {
          return this.playingAudio === item.id; // Verifica se o áudio atual está tocando
      },
      commentsToRead(item){
        let counter = 0
        item.comments.forEach(element => {
          if(element.seen == false && element.user_id != this.$root.session.id)
            counter++
        });
        return counter
      },
      chipColor(i){
        // Se está fechada, fica a cinza
        if(i.state.i18n['pt'] == 'Fechado'){
          return 'grey'
        }
        // Verificar se já foi vista a primeira vez. Se estiver a null então temos de usar os settings da primeira entrada de reclamação
        if(i.received_date == null){
          let limit = 7
          let red_line = 2
          
          this.$store.configurations.forEach(element => {
            if(element.key == "deadline_after_receipt")
              limit = parseInt(element.value)

            if(element.key == "notification_after_receipt"){
              red_line = parseInt(element.value)
            }
          });

            if(i.deadline <= red_line)
              return 'error';

            if(i.deadline >= limit/2)
              return 'success'

            return 'warning'

          
        }else{
          // Se entrarmos aqui é porque já foi vista uma vez
          let limit = 7
          let red_line = 2
          this.$store.configurations.forEach(element => {
            if(element.key == "deadline_for_investigation")
              limit = parseInt(element.value)

            if(element.key == "notification_after_investigation"){
              red_line = parseInt(element.value)
            }
          });

            if(i.deadline <= red_line)
              return 'error';

            if(i.deadline >= limit/2)
              return 'success'

            return 'warning'
        }
      },
      fillBaseData() {
  
        if(localStorage["complaints-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["complaints-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchComplaint(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["complaints-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Complaints.search(request).then(response => {

          let data = response.data
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetComplaint()
      {
        this.filter = {};
  
        this.searchComplaint();
      },
      viewComplaint(item){
        let v = item.id     
        this.$router.push('/' + this.$session.get('domain') + '/admin/complaints/' + v);
      },
      deleteComplaint(item){
        Complaints.delete(item.id)
          .then(() => {
            this.searchComplaint();
          });
      },
    },
    computed: {
      headers(){
        return [
          {
            text: '',
            sortable: false,
            value: 'readed',
          },
          {
            text: this.$t('globals.reference'),
            sortable: false,
            value: 'reference',
          },
          {
            text: this.$t('globals.created_date'),
            sortable: false,
            value: 'created_at',
          },
          {
            text: this.$t('globals.title'),
            sortable: false,
            value: 'title',
          },
          {
            text: this.$t('globals.description'),
            sortable: false,
            value: 'description',
          },
          {
            text: this.$t('globals.deadline'),
            sortable: false,
            value: 'deadline',
          },
          {
            text: this.$t('globals.status'),
            sortable: false,
            value: 'state',
          },
          { 
            text: this.$t('globals.options'),
            value: 'actions', 
            sortable: false 
          },
        ]
      },
      tableFooter(){
        return {
          'items-per-page-options': [5, 10, 15, 20],
          'items-per-page-text': this.$t('globals.lines_per_page')
        }
      },
      crumbs: function() {
        return [
          {
            text: this.$t('menu.complaints_management'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  