import BaseApi from "./BaseApi";

export default class Languages extends BaseApi{
    construct() {}

    static async list(params, tenant) {
        let url = Languages.getFullApiUrl(tenant) + "tenant-languages";

        return Languages.getAxiosInstance().get(url,{params: params});
    }

    static async getAvailableLanguages(params, tenant) {
        let url = Languages.getFullApiUrl(tenant) + "languages-available";

        return Languages.getAxiosInstance().get(url,{params: params});
    }

    static async updateLanguage(params){
        return await Languages.getAxiosInstance().put(Languages.getFullApiUrl() ,params);
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/languages/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/languages/";
    }
}