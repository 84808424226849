import BaseApi from "./BaseApi";

export default class Files extends BaseApi{
    construct() {}

    static async downloadFile(id) {
        let url = Files.getFullApiUrl() + 'download/' + id
        
        return await Files.getAxiosInstanceDownload(url)
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/files/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/files/";
    }
}