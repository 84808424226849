<template>
    <v-container fluid>
        <validation-observer ref="form" v-slot="{ invalid }" v-if="!loading">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-tabs v-model="tab" background-color="primary" dark centered fixed-tabs>
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab v-for="language in languages" :key="language.id">{{language.language}}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="language in languages" :key="language.language">
                        <v-row class="mt-8" >
                            <v-col cols="12">
                                <validation-provider v-slot="{ errors }" vid="title" name="Título" rules="|">
                                    <v-text-field
                                        v-model="fields.i18n[language.language].title"
                                        prepend-inner-icon="mdi-account-details"
                                        :label="$t('globals.title')+ ' *'"
                                        :error-messages="errors"
                                        clearable
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <v-tiptap v-model="fields.i18n[language.language].description" />
                            </v-col>
                            <v-col cols="12" md="3">
                                <validation-provider v-slot="{ errors }" vid="Anexo" name="Anexo" rules="|">
                                    <v-file-input
                                        :label="$t('white_labeling.attach')"
                                        v-model="fieldFiles[language.language].file"
                                        :error-messages="errors"
                                    >                                    
                                    </v-file-input>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="auto" v-if="fields.i18n[language.language].file != null">
                                <v-btn 
                                    outlined
                                    class="primary white--text"
                                    rounded
                                    @click="downloadFile(fields.i18n[language.language].file, fields.i18n[language.language].file_object)"
                                >
                                    Download: {{fields.i18n[language.language].file_object.name}}
                                </v-btn>
                            </v-col>
                        </v-row>                    
                    </v-tab-item>
                </v-tabs-items>
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed color="primary" type="submit">
                        {{$t('globals.save')}}
                    </v-btn>                
                </v-row>
                <v-row class="mt-6" align="center" justify="space-around">                
                    <v-col cols="12" align="end">
                        {{$t('white_labeling.final_result')}}: 
                        <span style="cursor: pointer;" class="font-italic text-decoration-underline" @click="openWebLink(getDashboardLink)">
                            {{getDashboardLink}}         
                        </span> 
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>
    </v-container>
</template>
<script>
import Complaints from '@/api/Complaints.js'
import Languages from "@/api/Languages.js"
import Files from "@/api/Files.js"

export default{
    props:{
        fields:{
            typeof: Object, 
            default: null
        }
    },
    data(){
      return{
        tab: 0,
        success: false,
        loading: true,
        languages:[],
        fieldFiles: []
      }
    },
    mounted(){
        this.fillBaseData()
    },
    computed:{
        getDashboardLink(){
            let l = process.env.VUE_APP_FRONTOFFICE_URL + this.$session.get('domain')

            return l
        }
    },
    methods:{
        async fillBaseData(){
            await Languages.list().then(({data}) => {
                this.languages = data;

                this.languages.forEach(element => {                    
                    
                    this.fieldFiles[element.language] = {file: null}
                    //this.fieldFiles[element.language].file = null                    
                });

                
                this.loading = false
                
            });
        },
        downloadFile(file, ob){
            Files.downloadFile(file).then((response)    =>  {
                let documentName =  ob.name

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");

                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        },
        openWebLink(link){
            window.open(link, '_blank')
        },
        submit(){
            this.loading = true

            this.$refs.form.validate().then((result) => {
                this.loading = true;
                
                if(!result){
                    this.loading = false;
                    return;
                }
        
                let data = {...this.fields};

                const formData = new FormData();
                this.languages.forEach(element => {                    
                    formData.append('files[' + element.language + ']', this.fieldFiles[element.language].file);
                });
                
                
        
                Complaints['setIntroduction'](data, this.fields.id).then(async () => {
                    // Atualizar anexos
                    await Complaints.setAttachment(formData, this.fields.id).then(()  =>  { });

                    this.success = true;
        
                    this.loading = false;

                    this.$emit('reload')
                    
                }).catch(err => {
                    this.loading = false;
        
                    if(err.response.status == 422) {
        
                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                    }
        
                    this.error.title = "Erro " + err.response.status;
        
                    this.error.message = err.response.data.message;
        
                    });
                })
        }
    }
}
</script>