<template>
    <v-snackbar
        v-bind="$attrs"
        v-on="$listeners"
        top
        right
        :timeout="timeout"
        color="success"
    >
        {{message || "Success!"}}

        <template v-slot:action="{attrs}">
            <v-btn
                fab
                text
                class="mb-2"
                v-bind="attrs"
                @click="close"
                color="white"
                small
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        message: String,
        timeout:{
            typeof: Number, 
            default: 2000
        }
    },
    methods: {
        close: function() {
            this.$emit("input", false)
        }
    }
}
</script>