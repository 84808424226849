<template>
    <section v-if="!loading">
        <v-container fluid v-if="!welcome">
            <validation-observer ref="form" v-slot="{ }">
                <v-form  v-if="paymentType == null">
                    <v-card style="margin: auto; display: block;"
                        class="py-3 my-3 rounded-xl elevation-0 fill-height w-50-md">
                        <v-row>
                            <v-col cols="12" class="text-h4 font-weight-bold">
                                {{ $t('subscriptions.payment_type_view.register') }}
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12" class="font-weight-bold">
                                        {{ $t('subscriptions.payment_type_view.select') }}
                                    </v-col>
                                    <!-- DESKTOP VERSION -->
                                    <v-col cols="12" class="d-none d-sm-block">
                                        <v-radio-group v-model="fields.payment_option" row>
                                            <v-radio class="mr-16" value="mb">
                                                <template v-slot:label>
                                                    <v-img class="ml-3" contain width="50px"
                                                        src="@/assets/payments/mb.png" />
                                                </template>
                                            </v-radio>
                                            <v-radio value="cc">
                                                <template v-slot:label>
                                                    <v-img class="ml-3" contain width="100px"
                                                        src="@/assets/payments/visa.png" />
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <!-- DESKTOP VERSION END -->
                                    <!-- MOBILE VERSION -->
                                    <v-col cols="12" class="d-sm-none">
                                        <div
                                            class="w-100 overflow-x-auto d-flex align-center justify-space-between hide-scrollbar">
                                            <v-btn
                                                :class="fields.payment_option == 'mb' ? 'btn-type-payment selected mr-2' : 'btn-type-payment mr-2'"
                                                @click="setPaymentOption('mb')">
                                                <v-img contain src="@/assets/payments/mb.png" />
                                            </v-btn>
                                            <v-btn
                                                :class="fields.payment_option == 'cc' ? 'btn-type-payment selected mr-2' : 'btn-type-payment mr-2'"
                                                @click="setPaymentOption('cc')">
                                                <v-img contain src="@/assets/payments/visa.png" />
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <!-- MOBILE VERSION END -->
                                    <v-col cols="12">
                                        <v-btn v-if="loader == false" :disabled="fields.payment_option == null" rounded
                                            depressed color="primary" class="btn-bottom" @click="submit">
                                            {{ $t('globals.pay') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <fieldset :disabled="false">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="font-weight-bold mb-4">
                                            {{ $t('subscriptions.payment_type_view.invoice_data') }}
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }"
                                                :vid="$t('subscriptions.payment_type_view.company_name')"
                                                :name="$t('subscriptions.payment_type_view.company_name')"
                                                rules="required">
                                                <v-text-field dense outlined v-model="fields.company.name"
                                                    :label="$t('subscriptions.payment_type_view.company_name')"
                                                    :error-messages="errors" />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }"
                                                :vid="$t('subscriptions.payment_type_view.company_address')"
                                                :name="$t('subscriptions.payment_type_view.company_address')"
                                                rules="required">
                                                <v-text-field dense outlined v-model="fields.company.address"
                                                    :error-messages="errors"
                                                    :label="$t('subscriptions.payment_type_view.company_address')" />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <validation-provider v-slot="{ errors }"
                                                :vid="$t('subscriptions.payment_type_view.postal_code')"
                                                :name="$t('subscriptions.payment_type_view.postal_code')"
                                                rules="required">
                                                <v-text-field dense outlined v-model="fields.company.postal_code"
                                                    :error-messages="errors"
                                                    :label="$t('subscriptions.payment_type_view.postal_code')" />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <validation-provider v-slot="{ errors }"
                                                :vid="$t('subscriptions.payment_type_view.local')"
                                                :name="$t('subscriptions.payment_type_view.local')" rules="required">
                                                <v-text-field dense outlined v-model="fields.company.local"
                                                    :error-messages="errors"
                                                    :label="$t('subscriptions.payment_type_view.local')" />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }"
                                                :vid="$t('subscriptions.payment_type_view.country')"
                                                :name="$t('subscriptions.payment_type_view.country')" rules="required">
                                                <v-autocomplete dense outlined v-model="fields.company.country"
                                                    :error-messages="errors"
                                                    :label="$t('subscriptions.payment_type_view.country')"
                                                    :items="['Portugal', 'Espanha']" />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }"
                                                :vid="$t('subscriptions.payment_type_view.nif')"
                                                :name="$t('subscriptions.payment_type_view.nif')"
                                                rules="required|pt_nif">
                                                <v-text-field dense outlined v-model="fields.company.nif"
                                                    :error-messages="errors"
                                                    :label="$t('subscriptions.payment_type_view.nif')" />
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }"
                                                :vid="$t('subscriptions.payment_type_view.email')"
                                                :name="$t('subscriptions.payment_type_view.email')" rules="required">
                                                <v-text-field dense outlined v-model="fields.company.email"
                                                    :error-messages="errors"
                                                    :label="$t('subscriptions.payment_type_view.email')" />
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                </fieldset>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </validation-observer>
            <div v-if="paymentType == 'mb'" class="py-4 my-4">
                <!-- DESKTOP VERSION -->
                <div class="d-none d-sm-block">
                    <v-card class="elevation-0 mt-6 rounded-xl " outlined style="margin: auto;" width="30%">
                        <v-row>
                            <v-col cols="12" align="center" class="mt-4 text-h6 font-weight-bold">
                                {{ $t('subscriptions.payment_type_view.payment_data') }}
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <v-row>
                                    <v-col cols="12" md="6" align="end" class="ma-0 pa-0">
                                        <v-img style="display: block; margin: auto;" width="50%"
                                            src="@/assets/payments/mb.png" />
                                    </v-col>
                                    <v-col cols="12" md="6" align="start" class="ma-0 pa-0" v-if="mb_object != null">
                                        <v-row class="mt-2" align="center" justify="center">
                                            <v-col cols="12">
                                                <span class="text-caption"><span class="font-weight-bold text-body-1">{{
        $t('subscriptions.payment_type_view.entity') }}:</span>
                                                    {{ mb_object.entidade }}</span>
                                            </v-col>
                                            <v-col cols="12">
                                                <span class="text-caption"><span class="font-weight-bold text-body-1">{{
        $t('subscriptions.payment_type_view.reference') }}:</span>
                                                    {{ mb_object.referencia }}</span>
                                            </v-col>
                                            <v-col cols="12">
                                                <span class="text-caption"><span class="font-weight-bold text-body-1">{{
        $t('subscriptions.payment_type_view.value') }}:</span>
                                                    {{ round_my_value(mb_object.valor) }}€</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" align="center" class="font-weight-bold text-caption mt-4">
                                {{ $t('subscriptions.payment_type_view.24_to_pay') }}
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
                <!-- DESKTOP VERSION END -->
                <!-- MOBILE VERSION -->
                <div class="d-sm-none">
                    <v-row>
                        <v-col cols="12" class="text-h5 font-weight-bold text-uppercase">
                            {{ $t('subscriptions.payment_type_view.do_payment') }}
                        </v-col>
                    </v-row>
                    <v-card class="elevation-0" style="margin: auto;">
                        <v-row>
                            <v-col cols="12" class="mt-4 text-h6 font-weight-bold text-uppercase">
                                {{ $t('subscriptions.payment_type_view.payment_data') }}
                            </v-col>
                            <v-col cols="3">
                                <v-img style="display: block; margin: auto;" contain height='72px'
                                    src="@/assets/payments/mb.png" />
                            </v-col>
                            <v-col cols="9" v-if="mb_object != null">
                                <v-row align="center" justify="center">
                                    <v-col cols="12">
                                        <span class="text-caption">
                                            <span class="font-weight-bold text-body-1">{{
        $t('subscriptions.payment_type_view.entity')
    }}:</span>
                                            {{ mb_object.entidade }}
                                        </span>
                                        <br>
                                        <span class="text-caption">
                                            <span class="font-weight-bold text-body-1">{{
        $t('subscriptions.payment_type_view.reference')
    }}:</span>
                                            {{ mb_object.referencia }}
                                        </span>
                                        <br>
                                        <span class="text-caption">
                                            <span class="font-weight-bold text-body-1">{{
        $t('subscriptions.payment_type_view.value')
    }}:</span>
                                            {{ round_my_value(mb_object.valor) }}€
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="font-weight-bold text-body-1">
                                {{ $t('subscriptions.payment_type_view.24_to_pay') }}
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-row>
                        <v-col cols="12" class="mt-6 font-weight-bold text-uppercase grey--text">
                            {{ $t('subscriptions.payment_type_view.after_pay') }}
                        </v-col>
                        <v-col cols="12" class="font-weight-bold text-caption text-uppercase grey--text">
                            {{ $t('subscriptions.payment_type_view.redirect') }}
                        </v-col>
                    </v-row>
                    <v-btn class="justify-center w-100 d-none d-flex btn-no-style" style="position: fixed;
                    bottom: 40px; left: 0;" onClick="window.location.href='https://ignoto.eu'">
                        <v-img src="@/assets/icons/logo_preto.jpg" max-width="175" />
                    </v-btn>
                </div>
                <!-- MOBILE VERSION END -->
            </div>
            <div fluid v-if="paymentType == 'cc'" class="py-4 my-4">
                <!-- DESKTOP VERSION -->
                <div class="d-none d-sm-block">
                    <v-row>
                        <v-col cols="12" align="center" class="text-h6 font-weight-bold">
                            {{ $t('subscriptions.payment_type_view.do_payment') }}
                        </v-col>
                    </v-row>
                    <v-card class="elevation-0 mt-6 rounded-xl w-30-md" outlined style="margin: auto; display: block;">
                        <v-row>
                            <v-col cols="12" align="center" class="mt-4">
                                {{ $t('subscriptions.payment_type_view.payment_data') }}
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <v-img cover src="@/assets/payments/visa.png" />
                                <div class="mt-6 font-weight-bold text-uppercase grey--text text-md-center"
                                    v-html="$t('subscriptions.payment_type_view.after_pay')"></div>
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <div class="font-weight-bold text-caption text-uppercase grey--text text-md-center"
                                    v-html="$t('subscriptions.payment_type_view.redirect')"></div>
                            </v-col>
                            <v-col cols="12" align="center" class="mb-6">
                                <v-btn rounded @click="goTo()" class="primary btn-bottom">
                                    {{ $t('subscriptions.menu.pay') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
                <!-- DESKTOP VERSION END -->
                <!-- MOBILE VERSION -->
                <div class="d-sm-none">
                    <v-row>
                        <v-col cols="12" class="text-h5 text-uppercase font-weight-bold">
                            {{ $t('subscriptions.payment_type_view.do_payment') }}
                        </v-col>
                    </v-row>
                    <v-card class="elevation-0" style="margin: auto;">
                        <v-row>
                            <v-col cols="12" class="mt-4 text-h6 text-uppercase">
                                {{ $t('subscriptions.payment_type_view.payment_data') }}
                            </v-col>
                            <v-col cols="12">
                                <v-img cover src="@/assets/payments/visa.png" />
                                <div class="mt-6 font-weight-bold text-uppercase grey--text text-md-center"
                                    v-html="$t('subscriptions.payment_type_view.after_pay')"></div>
                            </v-col>
                            <v-col cols="12">
                                <div class="font-weight-bold text-caption text-uppercase grey--text text-md-center"
                                    v-html="$t('subscriptions.payment_type_view.redirect')"></div>
                            </v-col>
                            <v-col cols="12" align="center" class="mb-6">
                                <v-btn rounded @click="goTo()" class="primary btn-bottom">
                                    {{ $t('subscriptions.menu.pay') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
                <!-- MOBILE VERSION END -->
            </div>
        </v-container>
        <v-container fluid v-else>
            <v-row align="center" justify="center" class="py-16 my-16 ">
                <v-col cols="12" align="center" class="text-h6">
                    <span>Olá </span>
                </v-col>
                <v-col cols="12" align="center" class="text-h4 py-4 my-4">
                    {{$t('subscriptions.ready')}}
                </v-col>
                <v-col cols="12" align="center" class="text-h3 py-4 my-4">
                    Vamos entrar no Portal.
                </v-col>
                <v-col cols="12" align="center" class="py-4 my-4">
                    <v-btn @click="closeWindow" x-large class="primary btn-bottom" rounded>Começar</v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="loader" hide-overlay persistent width="500">
            <v-card color="primary" dark>
                <v-card-text class="text-center">
                    {{$t('globals.processing_payment')}}
                    <v-progress-linear indeterminate color="white" class="mb-0 mt-8 mb-8"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </section>
</template>
<script>
import Subscriptions from '@/api/Subscriptions.js'

export default {
    name: 'TypeOfPayment',
    props: {
        fields: Object,
        welcome: {
            typeof: Boolean,
            default: false
        }
    },
    data() {
        return {
            loader: false,
            paymentType: null,
            mb_object: null,
            loading: true,
            waiting_payment: false,
            redirect_link: null
        }
    },
    mounted() {
        this.loading = false

        if (this.welcome == true) {
            return
        }

        if (this.fields.pendent_subscription != null) {
            this.waiting_payment = true
            this.paymentType = this.fields.pendent_subscription.payment_method

            if (this.paymentType == 'cc') {
                let metadata = JSON.parse(this.fields.pendent_subscription.metadata)
                this.redirect_link = metadata.redirectUrl
                setTimeout(() => {
                    window.open(metadata.redirectUrl, '_blank')
                }, "3000");
            }

            if (this.paymentType == 'mb') {
                this.mb_object = JSON.parse(this.fields.pendent_subscription.metadata)
            }

            this.$emit('lock-previous')
        }
    },
    methods: {
        round_my_value(v) {
            return v.toFixed(2)
        },
        closeWindow() {
            this.$emit('close-dashboard')
        },
        goTo() {
            window.open(this.redirect_link, '_blank')
        },
        setPaymentOption(option) {
            this.fields.payment_option = option;
        },
        submit() {
            this.loader = true

            Subscriptions.create(this.fields).then((resp) => {
                let data = JSON.parse(resp.data.msg)
                this.paymentType = this.fields.payment_option



                if (this.paymentType == 'mb')
                    this.mb_object = data

                if (this.paymentType == 'cc') {
                    if (data.transactionStatus == 'Success') {
                        setTimeout(() => {
                            window.open(data.redirectUrl, '_blank')
                        }, "3000");
                    }
                }
                this.$emit('lock-previous')

                this.loader = false
            });
        }
    }
}
</script>