<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-shield-account</v-icon> <span>{{$t('complaints.edit_crumb')}}</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <complaint v-else method="update" :complaint="complaint" @reload="fillBaseData"></complaint>
        </div>
    </section>
  </template>
  <script>
  import Complaint from '@/components/complaints/Complaint'
  import ComplaintAPI from "@/api/Complaints.js";
  
  export default {
    components: {
        Complaint
    },
    data: () => ({
        complaint: {},
        loading:true
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "complaints.manager"])) {
        this.$router.push('/admin');
      }
  
      this.fillBaseData()
    },
    methods:{
      async fillBaseData(){
        this.loading = true;
        await ComplaintAPI.find(this.$route.params.id).then(({data}) => { this.complaint = data; this.loading = false;});
        this.loading = false
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.complaints_management'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/complaints/list',
            exact: true,
          },
          {
            text: this.$t('complaints.edit_crumb'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  