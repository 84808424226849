<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="openDialog"
      >
          <v-card
            style="overflow:hidden !important;">
            <v-toolbar
              color="primary"
              dark
            >{{$t('delete_account.permanent')}}</v-toolbar>
            <v-card-text>     
              <v-row class="mt-4">
                <v-col cols="12">
                  {{$t('delete_account.insert_code')}}
                </v-col>
                <v-col cols="12">                  
                  <v-otp-input
                    length="5"
                    type="number"
                    v-model="confirmCode"
                  ></v-otp-input>
                </v-col>
              </v-row>         
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" md="6" align="start">
                  <v-btn
                    text
                    @click="$emit('close')"
                  >{{$t('globals.close')}}</v-btn>
                </v-col>
                <v-col cols="12" md="6" align="end" v-if='$root.session.hasPermission(["super"])'>
                  <v-btn :disabled="confirmCode.length < 5" @click="confirm" class="error">{{$t('globals.confirm')}}</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>
<script>
import Branding from "@/api/Branding.js";

export default{
    name: "ConfirmDeleteAccount",
    data(){
        return{
            openDialog: true,
            confirmCode: ''
        }
    },
    methods:{
      confirm(){
        let data = {
          key: this.confirmCode
        }
        Branding.confirmeDeletePermanent(data).then((resp) =>   {
            if(resp.data.success){
              localStorage['auth'] = 0;
  
              this.$router.push('/');
            }
        })
      }
    }
}
</script>