<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <v-form @submit.prevent="submit"  class="mb-16">
       <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>
      <fieldset :disabled="loading">
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
              <v-text-field
                v-model="fields.name"
                dense outlined
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.name') + '*'"
                clearable
                :error-messages="errors"
                @blur="nameChanged"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="initials" name="initials" rules="required">
              <v-text-field
                v-model="fields.initials"
                dense outlined
                prepend-inner-icon="mdi-moon-first-quarter"
                :label="$t('globals.initials') + ' *'"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="phone_number" name="Telefone" rules="required">
              <v-text-field
                v-model="fields.phone_number"
                dense outlined
                prepend-inner-icon="mdi-phone"
                :label="$t('globals.telephone')+  '*'"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
              <v-text-field
                v-model="fields.email"
                dense outlined
                prepend-inner-icon="mdi-at"
                label="Email *"
                clearable
                type="email"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="username" name="Username" rules="required">
              <v-text-field
                v-model="fields.username"
                dense outlined
                prepend-inner-icon="mdi-account-details"
                label="Username *"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="$root.session.id != fields.id">
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" vid="roles" name="Roles">
              <multiple-select
                v-model="fields.roles"
                dense outlined
                :items="roles"
                item-text="display_name"
                item-value="id"
                menu-props="auto"
                label="Roles *"
                prepend-inner-icon="mdi-shield-account"
                :error-messages="errors"
              ></multiple-select>
            </validation-provider>
          </v-col>
        </v-row>
          <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="password" name="Password">
              <v-text-field
                v-model="fields.password"
                dense outlined
                prepend-inner-icon="mdi-form-textbox-password"
                type="password"
                label="Password"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="12" align="center" v-if="method == 'update'">
            <div v-if="userMicrosoft">
              <v-row>
                <v-col cols="12" md="3">
                  <v-btn    
                    depressed
                    color="primary"
                    class="text-white"                     
                    rounded
                    @click="registerMicrosoftUser"
                  >
                    <v-icon class="mr-2">mdi-microsoft</v-icon>
                    {{userMicrosoft.userName}}
                  </v-btn> 
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn    
                    depressed
                    color="primary"
                    class="text-white"                     
                    rounded
                    @click="$msal.signOut()"
                    v-if="$msal.isAuthenticated()"
                  >
                    <v-icon class="mr-2">mdi-logout</v-icon>
                    Logout
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn    
                    depressed
                    color="error"
                    class="text-white"                     
                    rounded
                    @click="removeMicrosoftAccount"
                    v-if="fields.microsoft_identifier != null"
                  >
                    <v-icon class="mr-2">mdi-trash-can</v-icon>
                    Remover
                  </v-btn>
                </v-col>
              </v-row>                                       
            </div>
            <v-btn                      
              v-else
              depressed
              color="primary"
              class="text-white"                     
              rounded
              @click="$msal.signIn()"
            >
              <v-icon class="mr-2">mdi-microsoft</v-icon>
              Microsoft Login
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col cols="12">
            <v-radio-group prepend-icon="mdi-compare" label="Tema" v-model="fields.theme" @change="changeTheme" row>
              <v-radio v-for="theme in $themes" v-bind:key="theme.name" :label="theme.name" :value="theme"></v-radio>
            </v-radio-group>
            <h3></h3>
          </v-col>
        </v-row>
        <v-row>
          <template v-if="$root.session.id == $route.params.id">
            

            <v-col cols="12">
              <v-switch
                v-model="fields.has_2fa"
                color="success"
                @change="toggle2Fa"
                label="2FA"
                hide-details
              ></v-switch>
            </v-col>

            <template v-if="qrcode">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    {{$t('globals.code')}} 2FA *
                  </v-col>
                  <v-col align="center" justify="center" cols="12" md="4">
                    <div v-html="qrcode"></div>
                  </v-col>                  
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" vid="two_fa_code" name="Código 2FA" rules="required">
                      <v-otp-input
                        length="6"
                        :error-messages="errors"
                        v-model="fields.two_fa_code"
                      ></v-otp-input>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" align="center">
                    <v-btn class="success" @click="validar2FA">
                      Validar código
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              
            </template>
          </template>

          <v-col cols="12">
            <v-switch
              v-model="fields.is_active"
              color="success"
              :true-value="1"
              :false-value="0"
              :label="$t('globals.active')"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row class="mt-6" align="center" justify="space-around">
          <v-col align="center" justify="space-around">
            <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
              {{$t('globals.save')}}
            </v-btn>

            <v-btn v-if="!!user && !user.is_verified" depressed color="secondary" class="mr-5 ml-5 mb-5" type="button" @click="resendActivationEmail">
              {{$t('globals.resend_activate_email')}}
            </v-btn>

            <v-btn depressed color="error" class="mr-5 ml-5 mb-5" type="button" @click="reset">
              {{$t('globals.reset_password')}}
            </v-btn>

            
          </v-col>
        </v-row>
      </fieldset>
    </v-form>
    <DialogSuccess :opened="success" @on-ok="onSuccess" >
      {{$t('user.success')}}
    </DialogSuccess>
    <v-dialog
        v-model="password_reseted"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5">{{$t('globals.reset_password')}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">{{$t('globals.email_sent')}}</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="password_reseted = false"
            >
              {{$t('globals.close')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="activation_email_resent"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5">{{$t('globals.active_email')}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">{{$t('globals.email_sent')}}</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="activation_email_resent = false"
            >
              {{$t('globals.close')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </validation-observer>
</template>
<script>
import User from "@/api/ClientUsers.js";
import PrincipalUser from "@/api/User.js"
import Role from "@/api/Role.js";
import MultipleSelect from '@/components/ui/MultipleSelect.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import { msalMixin } from 'vue-msal'; 

export default {
  components: {
    MultipleSelect,
    DialogSuccess
  },
  mixins: [msalMixin],
  props: {
   user: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
  '$route.params.id'() {
    let parent = this
    User.find(this.$route.params.id)
      .then((r) => {
        parent.fields = r.data
      })
    },
    user: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};
        console.log(data)

        data.roles = data.roles.map((item) => { return item.id});

        this.fields = data;

        this.fields.id = data.id;

        this.fields.theme = this.$themes.find((item) => data.theme == null ? false : item.name == data.theme.name)

        if(this.fields.theme == undefined || this.fields.theme.length == 0){
          this.fields.theme = this.$themes[0];
        }
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    fields: {
      id: null,
      name: null,
      username:null,
      email:null,
      phone_number:null,
      is_active:0,
      has_2fa: null,
      two_fa_code: null,
      roles:[],
      theme: null,
      password: null,
      initials: null,
      microsoft_identifier: null
    },
    qrcode:null,
    loading:false,
    error: {
      title: '',
      message: '',
    },
    roles: [],
    password_reseted:false,
    activation_email_resent:false,
    success: false,
  }),
  mounted(){
    this.fillBaseData();
  },
  methods:{
    removeMicrosoftAccount(){
      PrincipalUser.removeMicrosoftAccount(this.fields.id).then(()  =>  {
        this.$emit('reload')
      })
    },
    registerMicrosoftUser(){
      PrincipalUser.registerMicrosoftLogin(this.fields.id, this.userMicrosoft.idTokenClaims.oid).then(()  =>  {
        this.$emit('reload')
      })
    },
    validar2FA(){
      PrincipalUser.login2Fa(this.fields.two_fa_code)
            .then((resp) => {
              console.log(resp)              
            })
    },
    nameChanged(){
      if(this.fields.initials != null)
        return

      let nameSplitter = this.fields.name.split(' ');
      let firstInitial = this.fields.name[0]
      let lastWord = nameSplitter[nameSplitter.length-1]
      let lastInitial = lastWord[0]

      this.fields.initials = firstInitial + lastInitial
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/' + this.$session.get('domain') + '/admin/settings/users');
    },
    changeTheme(theme){
      this.setTheme(theme)

      this.fields.theme = theme;
    },
    fillBaseData(){
      Role.list().then(({data}) => {
        this.roles = data;
      });
    },
    toggle2Fa()
    {
      if(this.fields.has_2fa){
        PrincipalUser.enable2FA()
          .then(() => {
            this.render2Fa()
          });

          return;
      }
      
      PrincipalUser.disable2FA()
          .then(() => {
            this.qrcode = null;
          });
    },
    render2Fa(){
      PrincipalUser.get2FA()
        .then(({data}) => {
          this.qrcode = data.svg;
        });
    },
    reset () {
      User.reset(this.user.email)
          .then(() => {

              this.password_reseted = true;
          });
    },
    resendActivationEmail() {
      User.resendActivationEmail(this.user.id)
        .then(() => {

            this.activation_email_resent = true;
        });
    },
    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        if(this.fields.password != undefined)
          this.fields.password = this.$encryptValue(this.fields.password)

        let tenant = this.$session.get('domain')

        User[this.method](this.fields, tenant).then(({data}) => {

          this.success = true;

          this.loading = false;

          if(data.id == this.$root.session.id){
            let user = User.getInstance(data);

            this.$root.session = user;

            return;
          }

          this.setTheme(this.$root.session.theme);
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
          this.fields.password = ''
      })
    }
  },
  computed: {
    userMicrosoft() {
      let user = null;
      if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
        user = {
          ...this.msal.user,
          profile: {}
        }
        if (this.msal.graph && this.msal.graph.profile) {
            user.profile = this.msal.graph.profile
        }
      }

      return user;
    }
  }
};
</script>
