<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {
  },

  data: () => ({
  }),
};
</script>
<style>
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.text-underline {
  text-decoration: underline !important;
}

.word-break {
  word-break: break-word;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.btn-no-style {
  background-color: transparent !important;
  box-shadow: none !important;
}
.w-70-md {
  width: 70%;
}
.w-50-md {
  width: 50%;
}
.w-30-md {
  width: 30%;
}
.promo-code-input input{
  padding-right: 90px !important;
}
.v-btn--block.btn-promo-validate{
  position: absolute !important;
  right: 12px;
  top: 12px;
  width: fit-content;
  min-width: unset !important;
  height: 40px !important;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 576px) {
  .mobile-modal {
    position: absolute !important;
    top: 0;
    height: 100%;
    width: 100% !important;
    left: 0;
    border: 5px solid black !important;
    padding-bottom: 64px;
  }

  .btn-bottom {
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: calc(100% + 2px);
    margin-bottom: -1px;
    margin-left: -1px;
    border-radius: 0 !important;
    height: 64px !important;
    letter-spacing: 6px !important;
    z-index: 9;
  }

  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg.btn-bottom {
    background-color: #E0E0E0 !important;
  }

  .register-tabs .v-tabs-slider-wrapper {
    display: none;
  }

  .register-tabs .v-tab {
    flex: none !important;
    width: fit-content !important;
    min-width: 0 !important;
    padding: 0;
    font-weight: bold;
    font-size: 16px;
  }

  .register-tabs .v-tab--disabled {
    opacity: 1;
    color: #000 !important;
  }

  .register-tabs .v-tab--disabled span {
    opacity: 0.5;
    color: rgba(0, 0, 0, .54) !important;
  }

  .v-application .text-center.text-left-md {
    text-align: left !important;
  }

  .btn-type-payment{
    width: 40vw;
    height: 40vw !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: 2px solid black;
    border-radius: 20px !important;
    padding: 20px;
  }
  .btn-type-payment .v-image{
    width: calc(40vw - 40px) !important;
    height: calc(40vw - 40px) !important;
  }
  .btn-type-payment.selected{
    border: 4px solid black;
  }

  .w-70-md,
  .w-50-md,
  .w-30-md {
    width: 100%;
  }
}
</style>