<template>
    <div  style="overflow: hidden !important;">
        <section class="section mobile-modal" ref="scrollDiv" style="overflow: auto !important;">
            <v-tabs v-model="tab" fixed-tabs class="d-none d-sm-block" touchless>
                <v-tab :disabled="disabledFirst" :key="1">1. {{ $t('subscriptions.menu.register') }}</v-tab>
                <v-tab :disabled="disabledSecond" :key="2">2. {{ $t('subscriptions.menu.pay') }}</v-tab>
                <v-tab :disabled="disabledFird" :key="3">3. {{ $t('subscriptions.menu.type') }}</v-tab>
            </v-tabs>
            <v-tabs v-model="tab" fixed-tabs class="d-sm-none register-tabs" touchless>
                <v-tab :disabled="disabledFirst" :key="1"><span>1{{ disabledFirst ? "" : ". "+$t('subscriptions.menu.register') }}</span></v-tab>
                <v-tab :disabled="disabledSecond" :key="2">&nbsp;&nbsp;>&nbsp;&nbsp;<span>2{{ disabledSecond ? "" : ". "+$t('subscriptions.menu.pay') }}</span></v-tab>
                <v-tab :disabled="disabledFird" :key="3">&nbsp;&nbsp;>&nbsp;&nbsp;<span>3{{ disabledFird ? "" : ". "+$t('subscriptions.menu.type') }}</span></v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" touchless>
                <v-tab-item :key="1" class="justify-center">
                    <RegisterAccount :showEmailConfirm="showEmailConfirm" @next="tab = tab + 1;" @goToLogin="$emit('goToLogin')" :isRegistered="isRegistered"
                        @next-step="nextStep(1)" :fields="fields" return-object />
                </v-tab-item>
                <v-tab-item :key="2">
                    <Payments @free-subscription="freeSubscription" @next-step="nextStep(2)" :fields="fields" />
                </v-tab-item>
                <v-tab-item :key="3" class="justify-center">
                    <TypeOfPayment @close-dashboard="closeWindow" @lock-previous="canUndo = false; disabledSecond = true; disabledFirst = true;" :fields="fields"
                        :welcome="welcome" />
                </v-tab-item>
            </v-tabs-items>
            <!-- DESKTOP VERSION -->
            <div class="footer d-none d-sm-block">
                <v-row>
                    <v-col cols="12">
                        <v-btn v-if="tab > 1 && canUndo && welcome == false" text @click="tab = tab - 1">
                            <v-icon>mdi-arrow-left</v-icon>
                            {{ $t('globals.previous') }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-img  v-if="false"
                            style="display: block; margin: auto; cursor: pointer;" 
                            src="@/assets/icons/logo_preto.jpg"
                            @click="openWebsite"
                            max-width="175" />
                    </v-col>
                </v-row>
            </div>
            <!-- DESKTOP VERSION END -->
            <!-- MOBILE VERSION -->
            <div class="footer d-sm-none mb-16">
                <v-row>
                    <v-col cols="12" align="start">
                        <v-btn v-if="tab > 1 && canUndo && welcome == false" text @click="tab = tab - 1" class="pb-4">
                            <v-icon>mdi-arrow-left</v-icon>
                            {{ $t('globals.previous') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <!-- MOBILE VERSION END -->
        </section>
        
    </div>
</template>
<style>
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-bottom: 2%;
    color: white;
    text-align: center;
}
</style>

<script>
import RegisterAccount from '@/components/register_account/RegisterAccount.vue'
import Payments from '@/components/register_account/Payments.vue'
import TypeOfPayment from '@/components/register_account/TypeOfPayment.vue'
import Tenant from "@/api/Tenant.js"

export default {
    name: "Register",
    components: {
        RegisterAccount,
        Payments,
        TypeOfPayment
    },
    props: {
        welcome: {
            typeof: Boolean,
            default: false
        },
        flag: {
            typeof:String,
            default: ''
        },
        pendent_subscription_data:{
            typeof:Object,
            default: null
        }
    },
    mounted() {
        console.log("FLAG")
        console.log(this.flag)
        if(this.flag == 'confirm-email'){
            this.showEmailConfirm = true
            return
        }


        if (this.welcome == true) {
            this.tab = 2
            this.disabledFirst = true
            this.disabledSecond = true
            this.disabledFird = false
            return
        }
        if (localStorage['auth'] == 1) {
            if (this.$store.tenant_company != null) {
                let aux = this.$store.tenant_company
                this.fields.company.name = aux.name
                this.fields.company.nif = aux.nif
                this.fields.company.address = aux.address
                this.fields.company.postal_code = aux.postal_code
                this.fields.company.local = aux.local
                this.fields.company.country = aux.country
                this.fields.company.email = aux.email

                this.fields.manager.name = aux.manager.name
                this.fields.manager.function = aux.manager.function
                this.fields.manager.email = aux.manager.email
                this.fields.manager.telephone = aux.manager.phone_number

                this.fields.manager.function = aux.manager_job

                this.fields.terms_conditions = true

                this.isRegistered = true

                this.tab = 1
                this.disabledFirst = true
                this.disabledSecond = false

                if(this.pendent_subscription_data == null){
                    if (aux.pendent_subscription != null) {
                        this.fields.pendent_subscription = aux.pendent_subscription
                        this.tab = 2
                        this.disabledFirst = true
                        this.disabledSecond = true
                        this.disabledFird = false
                    }                
                }else{
                        this.fields.pendent_subscription = this.pendent_subscription_data
                        this.tab = 2
                        this.disabledFirst = true
                        this.disabledSecond = true
                        this.disabledFird = false
                }
            }
        }

    },
    watch: {
        tab() {
            this.scrollToTop();
        }
    },
    data() {
        return {
            tab: 0,
            isRegistered: false,
            canUndo: true,
            disabledFirst: false,
            showEmailConfirm: false,
            disabledSecond: true,
            disabledFird: true,
            fields: {
                plan: null,
                pendent_subscription: null,
                company: {
                    name: null,
                    nif: null,
                    address: null,
                    postal_code: null,
                    local: null,
                    country: null,
                    email: null
                },
                manager: {
                    name: null,
                    function: null,
                    email: null,
                    password: null,
                    telephone: null
                },
                terms_conditions: false,
                payment_option: null,
                subscription_id: null,
                is_true: false,
                name: null
            },
        }
    },
    methods: {
        openWebsite(){
            window.open('https://ignoto.eu/', '_blank')
        },
        closeWindow() {
            this.$emit('close')
        },
        scrollToTop() {
            if (this.$refs.scrollDiv) {
                this.$refs.scrollDiv.scrollTop = 0;
            }
        },
        freeSubscription() {
            Tenant.freeSubscription(this.$session.get('domain')).then(() => {
                this.fields = {
                    plan: null,
                    pendent_subscription: null,
                    company: {
                        name: null,
                        nif: null,
                        address: null,
                        postal_code: null,
                        local: null,
                        country: null,
                        email: null
                    },
                    manager: {
                        name: null,
                        function: null,
                        email: null,
                        password: null,
                        telephone: null
                    },
                    terms_conditions: false,
                    payment_option: null,
                    subscription_id: null,
                    is_true: false,
                    name: null
                },
                    this.isRegistered = false
                this.$router.push('/')
            })
        },
        nextStep(step) {
            if (step == 1) {
                this.disabledSecond = false
            }
            if (step == 2) {
                this.disabledFird = false
            }

            this.tab = step
        },
    }
}
</script>