<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>{{$t('menu.users_management')}}</span>
      </h1>
      <v-icon color="primary">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      {{$t('globals.visualize')}}

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      {{$t('globals.remove')}}

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.options')}}</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  :to="'/' + $session.get('domain') + '/admin/settings/users/create'"
                  v-if="$root.session.hasPermission(['super', 'users.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.register')}}</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >

          <template v-slot:item.is_active="{ item }">
            <v-container class="px-0" fluid>
              <v-row align="center"
                  justify="center">
                  <v-col>
                    <v-switch
                      style="margin-top:0px"
                      :disabled="!$root.session.hasPermission(['super', 'users.update']) || item.username == 'super'"
                      v-model="item.is_active"
                      color="success"
                      @change="toggleUser(item)"
                      hide-details
                    ></v-switch>
                  </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item.roles="{item}">
            <div v-for="r in item.roles" v-bind:key="r.id">
              <h6>{{ r.display_name }}</h6>
            </div>
          </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewUser(item)"
            />
            <IconRemove
              v-if="item.can_delete == true && $root.session.hasPermission(['super', 'users.delete']) && item.username != 'super'"
              class="mr-2"
              @on-submit="deleteUser(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetUsers">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchUsers">
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.name')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.email"
                prepend-inner-icon="mdi-at"
                :label="$t('globals.email')"
                type="email"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.username"
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.username')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.phone_number"
                prepend-inner-icon="mdi-phone"
                :label="$t('globals.telephone')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-switch
                style="margin-top:0px"
                :label="$t('globals.active')"
                v-model="filter.is_active"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
              ></v-switch>
            </v-list-item>

            <v-list-item>
               <multiple-select
                  v-model="filter.role_ids"
                  :items="roles"
                  item-text="display_name"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.roles')"
                  prepend-inner-icon="mdi-shield-account"
              ></multiple-select>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
            {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import User from "@/api/ClientUsers.js";
import Role from "@/api/Role.js";
import MultipleSelect from '@/components/ui/MultipleSelect.vue';

export default {
  components: {
    MultipleSelect,
    IconRemove,
    IconView
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchUsers()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = this.$t('app_name') + ' - ' + this.$t('menu.users_management');

    Role.list().then(({data}) => {
      this.roles = data;
    });
  },
  data: () => ({
    items: [],
    total: 0,
    loading: false,
    searching: false,
    options: {},
    speedDial:false,
    filter: {
      name: null,
      email:null,
      username:null,
      phone_number:null,
      is_active:1,
      role_ids:[],
    },
    roles:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "users.read"])) {
      this.$router.push('/admin');
    }

    console.log(this.$root.session)
    this.fillBaseData();

  },
  methods:{
    fillBaseData() {

      if(localStorage["users-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["users-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchUsers(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["users-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      User.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });


        this.loading = false;

      });
    },
    resetUsers()
    {
      this.filter = {
        is_active: 1
      };

      this.searchUsers();
    },
    viewUser(item){
      this.$router.push('/' + this.$session.get('domain') + '/admin/settings/users/' + item.id);
    },
    deleteUser(item){
      User.delete(item.id)
        .then(() => {
          this.searchUsers();
        });
    },
    toggleUser(item) {
      User.updateActivation(item)
        .then(() => {
          this.searchUsers();
        });
    }
  },
  computed: {
    tableFooter(){
      return {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': this.$t('globals.lines_per_page')
      }
    },
    headers(){
      return [
        {
          text: this.$t('globals.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('globals.email'),
          sortable: false,
          value: 'email',
        },
        {
          text: this.$t('globals.username'),
          sortable: false,
          value: 'username',
        },
        {
          text: this.$t('globals.telephone'),
          sortable: false,
          value: 'phone_number',
        },
        {
          text: this.$t('globals.roles'),
          sortable: false,
          value: 'roles',
        },
        {
          text: this.$t('globals.active'),
          sortable: false,
          value: 'is_active',
        },
        { 
          text: this.$t('globals.options'),
          value: 'actions', 
          sortable: false 
        },
      ]
    },
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Utilizadores',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
