import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

const themes = {
  light: {
    primary: "#000000",
    primaryLight: "#000000",
    secondary: "#000000",
    accent: "#000000",
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    anchor: "#000000",
  },
  dark: {
    primary: "#000000",
    primaryLight: "#000000",
    secondary: "#000000",
    accent: "#000000",
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    anchor: '#000000',
},
}

Vue.prototype.$themes = [themes];

export default new Vuetify({
    lang: {
      locales: { pt },
      current: 'pt',
    },
    theme: {
        themes
    },
});
