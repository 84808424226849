import BaseApi from "./BaseApi";
export default class Invoice extends BaseApi{
    construct() {}

    static async download(id, tenant) {
        let url = Invoice.getFullApiUrl(tenant) + 'download/' + id
        
        return await Invoice.getAxiosInstanceDownload(url)
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/invoices/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/invoices/";
    }
}