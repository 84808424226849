<template>
    <validation-observer ref="form" v-slot="{ invalid }">
        <v-form class="mb-16" v-if="!show_paid">
            <v-container fluid>
                <v-card style="margin: auto; display: block;"
                    class="py-5 my-5 rounded-xl elevation-0 fill-height w-70-md">
                    <v-row no-gutters v-if="ended_subscription">
                        <v-col cols="12" class="text-h6 font-weight-bold text-md-center">
                            {{ $t('subscriptions.pay_view.hello') }} {{ fields.manager.name }}
                        </v-col>
                        <v-col cols="12" class="text-h6 font-weight-bold text-md-center">
                            {{ $t('subscriptions.pay_view.unfortunately') }}
                        </v-col>
                        <v-col cols="12" class="font-weight-medium text-md-center">
                            {{ $t('subscriptions.pay_view.invite') }}
                        </v-col>
                    </v-row>

                    <div v-if="!ended_subscription && !toPay">
                        <v-row justify="center">
                            <v-col cols="12" md="12" class="font-weight-medium text-md-center">
                                {{ $t('subscriptions.pay_view.hello') }} {{ fields.manager.name }}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="12" class="font-weight-bold text-h5 text-md-center">
                                {{ $t('subscriptions.pay_view.multiple_options') }}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="12" class="grey--text text-body-1 text-md-center">
                                {{ $t('subscriptions.pay_view.sub_today') }}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="12" align="center">
                                <v-btn class="primary btn-bottom" rounded @click="toPay = true">
                                    {{ $t('globals.pay') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center" v-if="$store.giveTrial">
                            <v-col cols="12" md="12" class="grey--text text-body-1 text-md-center">
                                {{ $t('subscriptions.pay_view.alternative') }}
                            </v-col>
                        </v-row>
                        <v-row justify="center" v-if="$store.giveTrial">
                            <v-col cols="12" md="12" align="center">
                                <v-btn class="primary" rounded @click="isFreeSubscription = true">
                                    {{ $t('subscriptions.pay_view.free_30') }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="grey--text text-caption text-md-center">
                                {{ $t('subscriptions.pay_view.no_risk') }}
                            </v-col>
                        </v-row>
                    </div>
                    <v-row no-gutters v-if="toPay" justify="center" align="center">
                        <v-col cols="12" md="7">
                            <v-row no-gutters>
                                <v-col cols="12" class="text-h4 font-weight-bold">
                                    {{ $t('globals.payment') }}
                                </v-col>
                                <v-col cols="12" class="font-weight-bold text-caption mt-2">
                                    {{ $t('subscriptions.pay_view.type_choose') }}
                                </v-col>
                                <v-col cols="12" md="8" class="mt-2">
                                    <validation-provider v-slot="{ errors }" :vid="$t('subscriptions.pay_view.type')"
                                        :name="$t('subscriptions.pay_view.type')" rules="required">
                                        <v-select :items="$t('subscriptions.pay_view.type_buildings')"
                                            v-model="subscriptionType" outlined :error-messages="errors"
                                            item-text="text" item-value="id" dense
                                            :label="$t('subscriptions.pay_view.type')" @change="changedType" />
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" class="font-weight-bold text-caption" v-if="subscriptionType != null">
                                    {{ subscriptionType == 'private' ? $t('subscriptions.pay_view.select_n_workers') :
        $t('subscriptions.pay_view.select_n_habitants') }}
                                </v-col>
                                <v-col cols="12" md="8" class="mt-2" v-if="subscriptionType != null">
                                    <validation-provider v-slot="{ errors }"
                                        :vid="$t('subscriptions.pay_view.n_workers')"
                                        :name="$t('subscriptions.pay_view.n_workers')" rules="required">
                                        <v-select :items="items" v-model="fields.subscription_id" outlined
                                            :error-messages="errors" dense item-value="id"
                                            :item-text="item => item.n_workers + ' ' + (subscriptionType == 'private' ? $t('globals.workers') : $t('globals.habitants'))"
                                            :label="subscriptionType == 'private' ? $t('subscriptions.pay_view.n_workers') : $t('subscriptions.pay_view.n_habitants')"
                                            @change="changedPlan" />
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12">
                                    <validation-provider v-slot="{ errors }"
                                        :vid="$t('subscriptions.pay_view.true_info')"
                                        :name="$t('subscriptions.pay_view.true_info')" rules="required">
                                        <v-checkbox v-model="fields.is_true" :error-messages="errors"
                                            :label="$t('subscriptions.pay_view.true_info')">
                                        </v-checkbox>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" md="8" style="position: relative;">
                                            <v-text-field label="Promocode" outlined dense rounded
                                                v-model="promoCode.value" class="promo-code-input" />
                                            <v-btn @click="checkPromoCode" rounded block
                                                class="primary btn-promo-validate">
                                                {{ $t('globals.validate') }}
                                            </v-btn>
                                        </v-col>
                                        <v-col v-if="promoCode.invalidCode" cols="12" class="mt-n4">
                                            <v-alert dense outlined type="error">
                                                {{ $t('globals.invalid_code') }}
                                            </v-alert>
                                        </v-col>
                                        <v-col v-if="promoCode.validCode" cols="12" class="mt-n4">
                                            <v-alert dense outlined type="success">
                                                {{ $t('globals.valid_code') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12"
                                    class="d-sm-none text-md-center grey--text text-caption font-weight-bold">
                                    * {{ $t('subscriptions.pay_view.reserves') }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="5" v-if="selectedPlan != null">
                            <v-card class="elevation-0 rounded-xl" outlined>
                                <v-card-title class="elevation-10 primary white--text rounded-xl">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            {{ selectedPlan.name }} <span class="text-body-1">{{ selectedPlan.fixedPrice
                                                }}
                                                € /{{ $t('globals.month') }}</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider color="white"></v-divider>
                                        </v-col>
                                        <v-col cols="12">
                                            {{ selectedPlan.n_workers }} {{ subscriptionType == 'public' ?
        $t('globals.habitants') : $t('globals.workers') }}
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text class="mt-4">
                                    <v-row>
                                        <v-col cols="12">
                                            {{ $t('globals.price') }} EUR: {{ globalPrice(selectedPlan.price, selectedPlan.in_month) }}
                                        </v-col>
                                        <v-col cols="12" class="mt-n4">
                                            VAT (23%) EUR: {{ calculateVat(selectedPlan) }}
                                        </v-col>
                                        <v-col cols="12" class="mt-n4">
                                            {{ $t('globals.discount') }} EUR: {{ getPromo }}€
                                        </v-col>
                                        <v-col cols="12" class="text-uppercase font-weight-black text-h6">
                                            {{ $t('globals.total') }} EUR: {{ getTotal }}
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row v-if="toPay">
                        <v-col cols="12" class="d-none d-sm-block text-md-center grey--text text-caption font-weight-bold">
                            * {{ $t('subscriptions.pay_view.reserves') }}
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-btn :disabled="invalid || !fields.is_true" rounded depressed color="primary"
                                @click="submit" class="btn-bottom">
                                {{ $t('globals.pay') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-container>
        </v-form>
        <v-dialog v-model="isFreeSubscription" v-if="isFreeSubscription" width="500px">
            <v-card class="elevation-0">
                <v-card-title class="primary white--text">
                    Subscrição gratuita
                </v-card-title>
                <v-card-text class="mt-4 text-center text-body-1">
                    Tem a certeza que deseja subscrever 30 dias grátis?
                </v-card-text>
                <v-card-actions class="text-center justify-center">
                    <v-btn class="primary white--text" rounded
                        @click="fields.payment_option = 'free'; $emit('free-subscription')">Sim</v-btn>
                    <v-btn rounded @click="isFreeSubscription = false">Não</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- DESKTOP VERSION -->
        <v-card style="margin: auto;"
            class="py-16 my-16 px-4 rounded-xl elevation-0 fill-height w-50-md d-none d-sm-block" v-if="show_paid">
            <v-row>
                <v-col cols="12" class="text-md-center text-h4 font-weight-bold">
                    {{ $t('subscriptions.register_view.verify_email') }}
                </v-col>
                <v-col cols="12" class="mt-6 text-body-1">
                    {{ $t('subscriptions.register_view.before_you_go') }}
                </v-col>
                <v-col cols="12" class="mt-6 text-body-1">
                    {{ $t('subscriptions.register_view.no_received') }}
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn @click="goToLogin" rounded class="primary">Login</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <!-- DESKTOP VERSION END -->
        <!-- MOBILE VERSION -->
        <v-card style="margin: auto;" class="py-4 my-4 px-4 rounded-xl elevation-0 fill-height d-sm-none"
            v-if="show_paid">
            <v-row>
                <v-col cols="12">
                    <span class="text-h4 font-weight-bold text-uppercase black--text">
                        {{ $t('subscriptions.register_view.verify_email1') }}
                        <br />
                        {{ $t('subscriptions.register_view.verify_email2') }}
                    </span>
                </v-col>
                <v-col cols="12" class="mt-6 text-body-1">
                    {{ $t('subscriptions.register_view.before_you_go')}}
                </v-col>
                <v-col cols="12" class="mt-6 text-body-1">
                    {{$t('subscriptions.register_view.no_received')}}
                </v-col>
                <v-btn class="justify-center w-100 d-none d-flex btn-no-style" style="position: fixed;
                    bottom: 40px; left: 0;" onClick="window.location.href='https://ignoto.eu'">
                    <v-img src="@/assets/icons/logo_preto.jpg" max-width="175" />
                </v-btn>
            </v-row>
        </v-card>
        <!-- MOBILE VERSION END -->
    </validation-observer>
</template>
<script>
import SubscriptionTypes from '@/api/SubscriptionTypes'
import Promocodes from "@/api/Promocodes.js"

export default {
    name: "Payments",
    props: {
        fields: Object,
    },
    data() {
        return {
            alreadyUsed: false,
            items: [],
            emailPromoCode: null,
            toPay: false,
            show_paid: false,
            isFreeSubscription: false,
            ended_subscription: false,
            selectedPlan: null,
            subscriptionType: null,
            allSubscriptions: [],
            promoCode: {
                value: null,
                invalidCode: false,
                discount: null,
                fixedPrice: null,
                validCode: false,
            }
        }
    },
    async mounted() {
        //this.subscriptionType = this.$t('subscriptions.pay_view.type_choose')[0]
        await this.fillBaseData()
        if (this.items.length > 0) {
            this.selectedPlan = this.items[0]
            this.selectedPlan.fixedPrice = this.selectedPlan.price
            this.fields.subscription_id = this.items[0].id
        }


        if (localStorage['promocional'] != undefined) {
            if (localStorage['promocional'] != null) {
                if(localStorage['promocional'] == 'start-free'){
                    if(this.$store.giveTrial){
                        this.isFreeSubscription = true
                        return
                    }
                }
            }
        }

        if (localStorage['plan'] != undefined) {
            if (localStorage['plan'] != null) {
                if(localStorage['plan'] == 'email-promotion'){
                    Promocodes.getURL(localStorage['promocional'], 'email').then((resp) => {
                        this.emailPromoCode = resp.data.code
                        this.toPay = true
                        return
                    })
                }
            }
        }

        let goToPay = false

        // Existe algum plano gravado na storage?
        // Porque se sim pode estar a vir do site ou até pode estar a vir de um email promocional
        if (localStorage['plan'] != undefined && localStorage['plan'] != 'undefined') {
            if (localStorage['plan'] != null) {
                if (localStorage['plan'] != "null") {
                    goToPay = true
                    this.fields.subscription_id = localStorage['plan']

                    let tipoSubscricao = null
                    this.allSubscriptions.forEach(element => {
                        if (element.id == this.fields.subscription_id) {
                            tipoSubscricao = element
                            this.subscriptionType = tipoSubscricao.institutional_type
                        }
                    });

                    let subs = []
                    this.allSubscriptions.forEach(element => {
                        if (element.institutional_type == tipoSubscricao.institutional_type)
                            subs.push(element)
                    });
                    this.items = subs

                    this.changedPlan(this.fields.subscription_id)
                }
            }
        }

        console.log(localStorage['promocional'])
        // Existe algum código promocional gravado na storage?
        // Porque se sim pode estar a vir direcionado de um email
        if (localStorage['promocional'] != undefined) {
            if (localStorage['promocional'] != null) {
                if (localStorage['promocional'] != "null") {
                    if (localStorage['promocional'] != "none") {
                        goToPay = true
                        Promocodes.getURL(localStorage['promocional']).then((resp) => {
                            this.promoCode.value = resp.data.code

                            if (this.fields.subscription_id != null)
                                this.checkPromoCode()
                        })                        
                    }
                }
            }
        }

        this.toPay = goToPay
    },
    methods: {
        changedType() {
            this.selectedPlan = null
            let returner = []
            this.allSubscriptions.forEach(element => {
                if (element.institutional_type == this.subscriptionType)
                    returner.push(element)
            });

            this.items = returner
        },
        calculateVat(plan) {
            return ((parseFloat(plan.price) * parseInt(plan.in_month)) * .23).toFixed(2)
        },
        checkPromoCode() {
            Promocodes.check({ code: this.promoCode.value }).then((resp) => {
                if (resp.data.success == false) {
                    this.promoCode.invalidCode = true
                    setTimeout(() => {
                        this.promoCode.invalidCode = false
                    }, "5000");
                } else {
                    let plano_original = null
                    this.allSubscriptions.forEach(element => {
                        if (element.id == this.selectedPlan.id)
                            plano_original = element
                    });

                    this.promoCode.discount = resp.data.value
                    let percentage = resp.data.value / 100
                    let discount = plano_original.fixedPrice * percentage


                    this.selectedPlan.discount = resp.data.value
                    this.selectedPlan.price = parseFloat(plano_original.fixedPrice) - parseFloat(discount)
                    
                    this.selectedPlan.discount_id = resp.data.id

                    this.alreadyUsed = true
                    this.promoCode.validCode = true
                    this.promoCode.value = null
                    localStorage['promocional'] = null

                    if(this.emailPromoCode == null){
                        setTimeout(() => {
                            this.promoCode.validCode = false
                        }, "5000");
                    }
                }
            })

        },
        async fillBaseData() {
            await SubscriptionTypes.list().then((res) => {
                this.allSubscriptions = res.data
            })
        },
        submit() {
            this.fields.plan = this.selectedPlan
            this.$emit('next-step')
        },
        async changedPlan(i) {
            this.items.forEach(element => {
                if (element.id == i)
                    this.selectedPlan = element
                if (this.selectedPlan != null)
                    this.selectedPlan.fixedPrice = this.selectedPlan.price
            });


            if(this.selectedPlan.website_direct_promo == true){
                if(this.selectedPlan.promocode != null){
                    await Promocodes.getURL(this.selectedPlan.promocode.promocode_url).then((resp) => {
                            this.promoCode.value = resp.data.code
                        })    
                }
            }
            
            if(this.emailPromoCode != null){
                    this.promoCode.value = this.emailPromoCode
            }

            if (this.promoCode.value != null)
                this.checkPromoCode()
        },
        globalPrice(p1, p2){
            let total = p1 * p2

            return total.toFixed(2)
        }
    },
    computed: {
        getTotal() {
            if (this.selectedPlan == null)
                return 0;

            let preco = this.selectedPlan.price * this.selectedPlan.in_month
            let vat = parseFloat(this.calculateVat(this.selectedPlan))

            let final = parseFloat(preco) + parseFloat(vat)

            return final.toFixed(2)
        },
        getPromo() {
            let p = this.promoCode.discount / 100
            let total = this.selectedPlan.fixedPrice * p 
            return this.promoCode.discount == null ? 0 : total.toFixed(2) * this.selectedPlan.in_month
        }
    }

}
</script>