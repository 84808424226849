var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : '',style:(this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'),attrs:{"elevation":"10"}},[_c('v-containerfluid',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16 ma-2 pa-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"name","name":_vm.$t('globals.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.code') +  ' *',"clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"display_name","name":_vm.$t('globals.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.name') +  ' *',"clearable":"","error-messages":errors},model:{value:(_vm.fields.display_name),callback:function ($$v) {_vm.$set(_vm.fields, "display_name", $$v)},expression:"fields.display_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"permissions","name":_vm.$t('globals.permissions'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiple-select',{staticClass:"d-none d-lg-block",attrs:{"items":_vm.permissions,"item-text":"display_name","item-value":"id","menu-props":"auto","label":_vm.$t('globals.permissions') +  ' *',"prepend-inner-icon":"mdi-lock","bigDrop":true,"error-messages":errors,"return-object":""},model:{value:(_vm.fields.permissions),callback:function ($$v) {_vm.$set(_vm.fields, "permissions", $$v)},expression:"fields.permissions"}}),_c('multiple-select',{staticClass:"d-sm-none",attrs:{"items":_vm.permissions,"item-text":"display_name","item-value":"id","menu-props":"auto","label":_vm.$t('globals.permissions') +  ' *',"prepend-inner-icon":"mdi-lock","bigDrop":false,"error-messages":errors,"return-object":""},model:{value:(_vm.fields.permissions),callback:function ($$v) {_vm.$set(_vm.fields, "permissions", $$v)},expression:"fields.permissions"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"users","name":_vm.$t('globals.users')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiple-select',{attrs:{"items":_vm.users,"item-text":function (item) { return _vm.$t('globals.name') + ': ' + item.name + ' - ' + _vm.$t('globals.username') + ': '+ item.username; },"item-value":"id","menu-props":"auto","label":_vm.$t('globals.users'),"prepend-inner-icon":"mdi-account-multiple","error-messages":errors,"return-object":""},model:{value:(_vm.fields.users),callback:function ($$v) {_vm.$set(_vm.fields, "users", $$v)},expression:"fields.users"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6 mb-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{class:(_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" "+_vm._s(_vm.$t('roles.success_save'))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }