import BaseApi from "./BaseApi";

export default class Branding extends BaseApi{
    construct() {}

    static async configure(params) {
        return await Branding.getAxiosInstance().post(Branding.getFullApiUrl() + 'configure',params);
    }

    static async get(params) {
        return await Branding.getAxiosInstance().get(Branding.getFullApiUrl(),params);
    }

    static async deletePermanent(){
        return await Branding.getAxiosInstance().post(Branding.getFullApiUrl() + 'delete-permanent');
    }

    static async confirmeDeletePermanent(params){
        return await Branding.getAxiosInstance().post(Branding.getFullApiUrl() + 'delete-permanent/confirme', params);
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/branding/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/branding/";
    }
}