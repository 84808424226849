import BaseApi from "./BaseApi";

export default class Notification extends BaseApi{
    construct() {}

    static async list(unread, offset = 0, tenant = null) {
        let params = {
            unread: unread,
            offset: offset
        }
        return await Notification.getAxiosInstance().get(Notification.getFullApiUrl(tenant),{params: params});
    }

    static async readAll() {
        const { data: response } = await Notification.getAxiosInstance().post(Notification.getFullApiUrl());

        return response;
    }

    static async read(id) {
        const { data: response } = await Notification.getAxiosInstance().post(Notification.getFullApiUrl() + id);

        return response;
    }

    static async unRead(id) {
        const { data: response } = await Notification.getAxiosInstance().post(Notification.getFullApiUrl() + 'unread/' + id);

        return response;
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant
        
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/notifications/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/notifications/";
    }

}