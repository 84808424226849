<template>
    <v-container fluid class="ma-0 pa-0">
        <div class="d-none d-sm-block">
            <v-divider></v-divider>
            <v-row class="mt-2 mb-2">
                <v-col cols="12" md="12" align="center">
                    <div v-if="user">
                        <v-row>
                            <v-col cols="12">
                                <v-btn    
                                depressed
                                color="primary"
                                class="text-white"                     
                                rounded
                                @click="microsoftLogin"
                                >
                                <v-icon class="mr-2">mdi-microsoft</v-icon>
                                {{user.userName}}
                                </v-btn> 
                            </v-col>
                            <v-col cols="12">
                                <v-btn    
                                depressed
                                color="primary"
                                class="text-white"                     
                                rounded
                                small
                                @click="$msal.signOut()"
                                v-if="$msal.isAuthenticated()"
                                >
                                <v-icon small class="mr-2">mdi-logout</v-icon>
                                Logout
                                </v-btn>
                            </v-col>
                        </v-row>                                       
                    </div>
                    <v-btn                      
                        v-else
                        depressed
                        color="primary"
                        class="text-white"                     
                        rounded
                        @click="$msal.signIn()"
                    >
                        <v-icon class="mr-2">mdi-microsoft</v-icon>
                        Microsoft Login
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
        <div class="d-sm-none">
            <v-row no-gutters>
                <v-col :cols="$msal.isAuthenticated() ? '10' : '12'">
                    <v-btn small v-if="$msal.isAuthenticated()" class="primary" block @click="microsoftLogin">
                        <v-icon small class="mr-2">mdi-microsoft</v-icon>
                        {{splitEmail(user.userName)}}
                    </v-btn>
                    <v-btn small v-else class="primary" block @click="$msal.signIn()">
                        <v-icon small class="mr-2">mdi-microsoft</v-icon>
                        Microsoft Login
                    </v-btn>
                </v-col>
                <v-col cols="2" v-if="$msal.isAuthenticated()">
                    <v-btn @click="$msal.signOut()" text small class="gray" block>
                        <v-icon small>mdi-swap-horizontal</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
import { msalMixin } from 'vue-msal'; 
import User from '@/api/User.js'

export default{
    name:"Microsoft",
    mixins: [msalMixin],
    methods:{
        async microsoftLogin(){
            if(this.$msal.isAuthenticated()){
                await User.logout(this.$root.session != undefined ? this.$root.session.id : null)
            .then(() => {
            }).catch(() => {
            })
                User.microsoftLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid)
                .then(({data}) => {

                if (data.two_factor) {
                    this.$router.push('/2fa');

                    return;
                }

                localStorage['auth'] = 1;

                this.$router.push('/admin');
                }).catch(err => {
                
                if(err.response.status == 422) {

                    this.$refs.login.setErrors(err.response.data.errors);
                    
                    return;
                }
            });
            }else{
                this.$msal.signIn()
            }
        },
        splitEmail(email){
            let f = email.split('@')
            return f[0]
        }
    },
    computed:{
        user() {
            let user = null;
            if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
                user = {
                ...this.msal.user,
                profile: {}
                }
                
                if (this.msal.graph && this.msal.graph.profile) {
                    user.profile = this.msal.graph.profile
                }
            }
            console.log(user)

            return user;
        }
    }
}
</script>