import BaseApi from "./BaseApi";

export default class Tenant extends BaseApi{
    construct() {}


    static async create(params) {
        return await Tenant.getAxiosInstance().post(Tenant.getApiUrl(), params);
    }

    static async checkData(params){
        return await Tenant.getAxiosInstance().get(Tenant.getApiUrl() + 'check-data', {params: params})
    }

    static async freeSubscription(tenant){
        return await Tenant.getAxiosInstance().put(Tenant.getFullApiUrl(tenant) + 'free-subscription');
    }

    static getFullApiUrl(tenant){
        let domain = tenant
        if(tenant == null)
            domain = location.pathname.split('/')[1]
            
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/tenants/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/tenants/";
    }
}