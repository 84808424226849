import BaseApi from "./BaseApi";

export default class Dashboard extends BaseApi{
    construct() {}

    static async getStatistics() {
        return await Dashboard.getAxiosInstance().get(Dashboard.getFullApiUrl());
    }

    static async markFirstView(){
        return await Dashboard.getAxiosInstance().get(Dashboard.getFullApiUrl() + 'mark-first-view');
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/dashboard/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/dashboard/";
    }
}