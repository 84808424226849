import BaseApi from "./BaseApi";

export default class Permission extends BaseApi{
    construct() {}

    static async list(params) {
        return await Permission.getAxiosInstance().get(Permission.getFullApiUrl() + "list",{params: params});
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/permissions/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/permissions/";
    }
}