import BaseApi from "./BaseApi";

export default class Severities extends BaseApi{
    construct() {}

    static async list(params) {
        let url = Severities.getFullApiUrl() + "list";

        return Severities.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/severities/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/severities/";
    }
}