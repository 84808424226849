<template>
    <v-app>
        <v-dialog v-model="registerAccounts" fullscreen>
            <v-card class="elevation-0">
                <Register />
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
import Register from "@/views/register_account/Register.vue"

export default{
    name:"InvalidSubscription",
    components:{
        Register
    },
    mounted(){
        if(this.$store.tenant_company.pendent_subscription == null){
            if(this.$store.subscription != null){
                if(this.$store.subscription.paid)
                    this.$router.push('/')
                if(this.$store.subscription.active)
                    this.$router.push('/')
            }
        }
        if(this.$store.subscription != null)
            if(this.$store.subscription.active)
                this.$router.push('/')
    },
    data(){
        return{
            registerAccounts: true,
        }
    }
}
</script>