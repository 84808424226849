var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}),_c('h1',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-shield-account")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('menu.subscriptions')))])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-container',[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.options')))])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"info","large":""},on:{"click":function($event){_vm.searching = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.search')))])]),(false)?_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$root.session.hasPermission(['super', 'subscriptions.create']))?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":"","to":'/' + _vm.$session.get('domain') + '/admin/settings/subscriptions/create'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,false,722713301)},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.register')))])]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paid ? _vm.$t('globals.yes') : _vm.$t('globals.no'))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? _vm.$t('globals.yes') : _vm.$t('globals.no'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.paid == false)?_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.openPaymentView(item)}}}):_vm._e(),(item.invoice_id != null)?_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"success","text":_vm.$t('globals.download_invoice'),"top":""}},[_c('v-btn',{attrs:{"fab":"","icon":"","color":"success","small":""},on:{"click":function($event){return _vm.downloadInvoice(item)}}},[_c('v-icon',[_vm._v(" mdi-download-box ")])],1)],1):_vm._e()]}}])})],1),_c('v-navigation-drawer',{staticClass:"pt-6",attrs:{"fixed":"","right":"","hide-overlay":"","width":"520"},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.searching = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"absolute":"","right":"","small":"","color":"error"},on:{"click":_vm.resetSubscriptions}},[_vm._v(" "+_vm._s(_vm.$t('globals.clean_fields'))+" ")])],1)],1),_c('v-list',{staticClass:"pt-10"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchSubscriptions.apply(null, arguments)}}},[_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.search'))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.showPayment),callback:function ($$v) {_vm.showPayment=$$v},expression:"showPayment"}},[_c('v-card',{staticClass:"elevation-0"},[(_vm.showPayment)?_c('Register',{attrs:{"pendent_subscription_data":_vm.pendent_subscription_data,"showPayment":_vm.showPayment}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }